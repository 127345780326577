
// Ref name: GET_GLOBAL_MARKETS_REQUEST
export const GET_GLOBAL_MARKETS_BEGIN = "GET_GLOBAL_MARKETS_BEGIN";
export const GET_GLOBAL_MARKETS_SUCCESS = "GET_GLOBAL_MARKETS_SUCCESS";
// Ref name: GET_GLOBAL_MARKETS_ERROR
export const GET_GLOBAL_MARKETS_FAILURE = "GET_GLOBAL_MARKETS_FAILURE";

/** Store details related action type */
// Ref name: GET_GLOBAL_MARKETS_REQUEST
export const GET_STORE_DETAILS_BEGIN = "GET_STORE_DETAILS_BEGIN";
export const GET_STORE_DETAILS_SUCCESS = "GET_STORE_DETAILS_SUCCESS";
// Ref name: GET_STORE_DETAILS_ERROR
export const GET_STORE_DETAILS_FAILURE = "GET_STORE_DETAILS_FAILURE";


/** Onboarding details related action type */
export const GET_ONBOARDING_BEGIN = "GET_ONBOARDING_BEGIN";
export const GET_ONBOARDING_SUCCESS = "GET_ONBOARDING_SUCCESS";
export const GET_ONBOARDING_FAILURE = "GET_ONBOARDING_DETAILS_FAILURE";
export const UPDATE_ONBOARDING_BEGIN = "UPDATE_ONBOARDING_BEGIN";
export const UPDATE_ONBOARDING_SUCCESS = "UPDATE_ONBOARDING_SUCCESS";
export const UPDATE_ONBOARDING_FAILURE = "UPDATE_ONBOARDING_FAILURE";

/** Onboarding details related action type */
export const GET_MARKETS_BEGIN = "GET_MARKETS_BEGIN";
export const GET_MARKETS_SUCCESS = "GET_MARKETS_SUCCESS";
export const GET_MARKETS_FAILURE = "GET_MARKETS_DETAILS_FAILURE";
export const CREATE_MARKET_BEGIN = "CREATE_MARKET_BEGIN";
export const CREATE_MARKET_SUCCESS = "CREATE_MARKET_SUCCESS";
export const CREATE_MARKET_FAILURE = "CREATE_MARKET_FAILURE";
export const DELETE_MARKET_BEGIN = "DELETE_MARKET_BEGIN";
export const DELETE_MARKET_SUCCESS = "DELETE_MARKET_SUCCESS";
export const DELETE_MARKET_FAILURE = "DELETE_MARKET_FAILURE";
export const UPDATE_MARKET_BEGIN = "UPDATE_MARKET_BEGIN";
export const UPDATE_MARKET_SUCCESS = "UPDATE_MARKET_SUCCESS";
export const UPDATE_MARKET_FAILURE = "UPDATE_MARKET_FAILURE";

// Ref name: GET_SETTINGS_REQUEST
export const GET_SETTINGS_BEGIN = "GET_SETTINGS_BEGIN";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
// Ref name: GET_SETTINGS_ERROR
export const GET_SETTINGS_FAILURE = "GET_SETTINGS_FAILURE";
export const UPDATE_SETTINGS_BEGIN = "UPDATE_SETTINGS_BEGIN";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAILURE = "UPDATE_SETTINGS_FAILURE";

export const GET_CURRENCIES_BEGIN = "GET_CURRENCIES_BEGIN";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAILURE = "GET_CURRENCIES_FAILURE";


export const GET_EMBEDED_BLOCK_STATUS_BEGIN = "GET_EMBEDED_BLOCK_STATUS_BEGIN";
export const GET_EMBEDED_BLOCK_STATUS_SUCCESS = "GET_EMBEDED_BLOCK_STATUS_SUCCESS";
export const GET_EMBEDED_BLOCK_STATUS_FAILURE = "GET_EMBEDED_BLOCK_STATUS_FAILURE";