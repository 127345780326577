import React from 'react';
import { Modal } from '@shopify/polaris';

const InvalidSession = ({ storeId }) => {
  const redirectToLoginPage = () => {
    window.location.href = `https://${storeId}/admin/apps`;
  };

  return (
    <Modal
      onClose={() => storeId && storeId.trim().length > 0 && redirectToLoginPage()}
      open={true}
      title={
        <div>
          {storeId && storeId.trim().length > 0 && (
            <div className='content h2'>
              Invalid session, please log in again!!
            </div>
          )}
          {(storeId === null ||
            storeId.trim().length <= 0) && (
              <div className='content h2'>
                Your session expired. Please go to apps and open Multi Country Pricing.
                again.
              </div>
            )}
        </div>
      }
      primaryAction={storeId && storeId.trim().length > 0 && {
        content: 'Log In',
        onAction: redirectToLoginPage
      }}
    ></Modal>
  );
}

export default InvalidSession;
