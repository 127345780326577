import APIService from "./APIService";

const API_END_POINT = process.env.REACT_APP_ADMIN_API_ENDPOINT + "app-plans";

export default {
  applyPlan(planId, planData) {
    return APIService.post(API_END_POINT + '/apply/' + planId, planData);
  },
  fetchPlan() {
    return APIService.get(`${API_END_POINT}`);
  },
}