import React from 'react';
import LayoutStyles from './Layout.module.scss';
import PropTypes from 'prop-types';

const Layout = ({ children, maxWidth }) => {
  return (
    <div style={maxWidth ? { maxWidth: `${maxWidth}px` } : {}} className={`${LayoutStyles['container']} d-flex flex-column`}>
      {children}
    </div>
  );
};

Layout.propTypes = {
  maxWidth: PropTypes.number
}


export default Layout;