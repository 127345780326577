import { GET_CURRENCIES_BEGIN, GET_CURRENCIES_SUCCESS, GET_CURRENCIES_FAILURE } from '../actions/actionTypes';

const initialState = {
  isLoading: false
}

const currencies = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCIES_BEGIN:
      return { ...state, isLoading: true, error: {} };
    case GET_CURRENCIES_SUCCESS:
      return { ...state, currencies: [...action.payload.currencies], isLoading: false, error: {} };
    case GET_CURRENCIES_FAILURE:
      return { ...state, error: action.payload.error, isLoading: false };
    default:
      return state;
  }
}

export default currencies;