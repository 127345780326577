import React, { useState } from 'react';
import { Modal } from "@shopify/polaris";
import { useEffect } from 'react';
import FrozePanelStyles from './styles.module.scss';
import Cookies from 'universal-cookie';

const FrozeAdminPanel = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [time, setTime] = useState(null);
  const [intervalId, setIntervalId] = useState();

  useEffect(() => {
    const cookies = new Cookies();
    cookies.remove(`${props.storeId}_mcp_freezeTime`, { path: '/' });
    setTime(msToTime(props.timeInMs));
    let changedTime = props.timeInMs;
    const intervalId = setInterval(() => {
      // changedTime = (parseInt(changedTime / 1000) - 1) * 1000;
      if(cookies.get(`${props.storeId}_mcp_freezeTime`)) {
        changedTime = parseInt(cookies.get(`${props.storeId}_mcp_freezeTime`)) - new Date().getTime();
      } else {
        changedTime = (parseInt(changedTime / 1000) - 1) * 1000;
      }
      
      const currrentDate = new Date();
      const timeRemaning = currrentDate.setSeconds(currrentDate.getSeconds() + (changedTime/1000));
      cookies.set(`${props.storeId}_mcp_freezeTime`, timeRemaning, { path: '/' });
      if (changedTime <= 0) {
        clearInterval(intervalId);
        if (props.closeFreezeAdminPanel) {
          props.closeFreezeAdminPanel();
        }
        setIsModalOpen(false);
      }
      setTime(msToTime(changedTime));
    }, 1000);
    setIntervalId(intervalId);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [])

  const msToTime = (duration) => {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)));

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return {
      hours,
      minutes,
      seconds
    }
  }

  return (
    <Modal
      open={isModalOpen}
      // onClose={() => { setIsModalOpen(false) }}
      title="You will not be able to change anything in application"
    >
      <Modal.Section>
        {time && <div className={FrozePanelStyles["timer-counter"]}>
          {time.hours}<span>hr</span> : {time.minutes}<span>min</span> : {time.seconds}<span>sec</span>
        </div>}
      </Modal.Section>
    </Modal>
  )
}

export default FrozeAdminPanel;