import { combineReducers } from 'redux';
import authReducer from './authReducer';
import globalMarketsReducer from './globalMarketsReducer';
import marketReducer from './marketReducer';
import onboardingReducer from './onboardingReducer';
import settingsReducer from './settingsReducer';
import currenciesReducer from './currenciesReducer';
import embededBlockStatusReducer from './embededBlockStatusReducer';


const rootReducer = combineReducers({
  auth: authReducer,
  globalMarkets: globalMarketsReducer,
  onboarding: onboardingReducer,
  markets:marketReducer,
  settings: settingsReducer,
  currencies: currenciesReducer,
  embededBlockStatus: embededBlockStatusReducer,
});

export default rootReducer;
