import React from 'react';
import PropTypes from 'prop-types';
import Styles from './styles.module.scss';
import SectionDivider from '../SectionDivider';
import Accordion from "../../components/common/Accordion";
import { Icon, TextContainer, Subheading, DisplayText } from '@shopify/polaris';
import {
  CircleLeftMajor
} from '@shopify/polaris-icons'
const EmailSettings = props => {

  return (
    <>
      <div className={`faq-header ${Styles['header']}`}>
        <div className="d-flex align-items-center">
          <div onClick={props.toggleFaq}>
            <Icon source={CircleLeftMajor} color="indigo" />
          </div>
          <div className="mr-15"></div>
          <DisplayText size="small"> This is email settings</DisplayText>
        </div>
      </div>
      <div className={Styles['spacer']}></div>
      <Accordion
        title="How do i service or repair my watch?"
      >
        <TextContainer>
          <p>
            Your mailing list lets you contact customers or visitors who
            have shown an interest in your store. Reach out to them with
            exclusive offers or updates about your products.
          </p>
        </TextContainer>
      </Accordion>

      <SectionDivider size="30" />

      <Accordion
        title="How do i service or repair my watch?"
      >
        <TextContainer>
          <p>
            Your mailing list lets you contact customers or visitors who
            have shown an interest in your store. Reach out to them with
            exclusive offers or updates about your products.
          </p>
        </TextContainer>
      </Accordion>

      <SectionDivider size="30" />

      <Accordion
        title="How do i service or repair my watch?"
      >
        <TextContainer>
          <p>
            Your mailing list lets you contact customers or visitors who
            have shown an interest in your store. Reach out to them with
            exclusive offers or updates about your products.
          </p>
        </TextContainer>
      </Accordion>

      <SectionDivider size="30" />

      <Accordion
        title="How do i service or repair my watch?"
      >
        <TextContainer>
          <p>
            Your mailing list lets you contact customers or visitors who
            have shown an interest in your store. Reach out to them with
            exclusive offers or updates about your products.
          </p>
        </TextContainer>
      </Accordion>

      <SectionDivider size="30" />

      <Accordion
        title="How do i service or repair my watch?"
      >
        <TextContainer>
          <p>
            Your mailing list lets you contact customers or visitors who
            have shown an interest in your store. Reach out to them with
            exclusive offers or updates about your products.
          </p>
        </TextContainer>
      </Accordion>

      <SectionDivider size="30" />

    </>
  );
};


export default EmailSettings;