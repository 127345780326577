

import React, { useState, useCallback, useEffect } from 'react';
import { default as Layout1 } from '../../../components/onboarding/Layout';
import Title from '../../../components/onboarding/title';
import { connect } from "react-redux";
import { Card, TextContainer, Heading, List, Button, Toast, Banner } from '@shopify/polaris';
import Divider from '../../../components/onboarding/Divider';
import { useHistory } from 'react-router-dom';
import { updateOnboarding } from '../../../redux/actions/onboardingActions';
import { OnboardingStatus } from '../../../constants/common';
import settingsApi from '../../../services/settingsApi';
import pricingApi from '../../../services/pricingApi';
import { CircleInformationMajor } from '@shopify/polaris-icons';
import MCPFooterHelp from '../../../components/common/MCPFooterHelp';


const Welcome = ({ updateOnboarding, ...props }) => {
  const { error } = props;
  const [choice, setChoice] = useState('PROCEED_BY_SELF');
  const [toast, setToast] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if(error && error.error) {
      if(error.error !== "Invalid Session") {
        setToast(true);
      }
    }
  }, [error])

  // Set the option selected by the User
  const handleChange = (_checked, val) => {
    setChoice(val)
  }

  // Based on user's choice update the onboarding details and take to step1 or else to help page
  const handleClick = () => {
    setApiCalled(true);
    settingsApi.getSettings().then(settings => {
      if(settings.data && settings.data.chargeStatus && settings.data.chargeStatus === "active") {
        const toBeUpdatedData = {
          currentStepNumber: 1,
          status: OnboardingStatus.ONBOARDING_STARTED,
        }
        // Dispatch the action to update the onboarding detail
        updateOnboarding(toBeUpdatedData);
        setApiCalled(false);
      } else {
        if(process.env.REACT_APP_ENV !== 'local') {
          pricingApi.fetchPlan().then(plan => {
            if(plan.data && plan.data.length > 0 && plan.data[0].id) {
              pricingApi.applyPlan(plan.data[0].id, {}).then((url) => {
                if(url.data && url.data.url) {
                  window.location.href = url.data.url;
                } else {
                  setToast(true);
                }
              })
              .catch(err => {
                setToast(true);
              })
              .finally(() => {
                setApiCalled(false);
              })
            }
          })
          .catch(error => {
            setToast(true);
          })
          .finally(() => {
            setApiCalled(false);
          })
        } else {
          const toBeUpdatedData = {
            currentStepNumber: 1,
            status: OnboardingStatus.ONBOARDING_STARTED,
          }
          // Dispatch the action to update the onboarding detail
          updateOnboarding(toBeUpdatedData);
          setApiCalled(false);
        }
      }
    })
    .finally(() => {
      setApiCalled(false);
    }); 
  }

  const toggleActive = useCallback(() => setToast((toast) => !toast), []);

  const toastMarkup = toast ? (
    <Toast content="Something went wrong!" duration={1500} error onDismiss={toggleActive} />
  ) : null;

  return (
    <Layout1>
        
      {/* Show the onboarding title */}
      <Title
        displayText="Please read these two points carefully"
      />

      <Divider />
      {/* Show onboarding Body card */}
      <div className="welcome-conatiner">
        <Card sectioned>
          <Banner icon={CircleInformationMajor}>
            <p>Our app works best for stores which are getting majority of their orders from online store and not from sales channels like Facebook, Instagram etc.</p>
          </Banner>
          <div className="mt-15">
            <Banner icon={CircleInformationMajor}>
              <p>Also, our app may not be compatible with all the apps on your store. If you face any issues after setting up the app, <span className="bold-font">please message us in the chat box from right bottom corner of the screen.</span></p>
            </Banner>
          </div>
          <div className="mt-15">
            <span className='bold-font'>Note:</span> The app creates country wise variants in order to achieve international pricing.
            {/* <Banner icon={CircleInformationMajor}>
              <p>The app creates country wise variants in order to achieve international pricing.</p>
            </Banner> */}
          </div>
        </Card>
      </div>
      {/* <Card sectioned>

        <TextContainer spacing="tight">
          <div className="blue-color"><Heading>
            1. What can I do with this app?
          </Heading></div>
          <p>This app makes your Shopify store <span className="bold-font">truly international in nature.</span> You can set <span className="bold-font">different prices</span> for different countries for each product. You can also <span className="bold-font">control the currency</span> in which your customers can see and pay.</p>
        </TextContainer>
        <div className="mt-40">
          <TextContainer spacing="tight">
            <div className="blue-color"><Heading>2. Is it suited for my Store?</Heading></div>
            <p>This app is suitable for you if you are</p>
            <List type="number">
                <List.Item>Getting majority of your orders from your online website and not via from other sales channels like Facebook or Instagram etc</List.Item>
                <List.Item>Having less than 2000 products. </List.Item>
            </List>
          </TextContainer>
        </div>
        <div className="mt-40">
          <TextContainer spacing="tight">
            <div className="blue-color"><Heading>
              3. Is it compatible with all the Shopify Apps?
            </Heading></div>
            <p> Our app creates country level product variants, so it will not work with any app which also creates product variants. Please see the complete list <span className="emailLink cursor-pointer link" onClick={() => window.open("https://docs.google.com/document/d/10GJIqAScsekbwnitHm2jN0ok28y9sE9HSvi-L9dH24c/edit?usp=sharing", "_blank")}>here.</span></p>
          </TextContainer>
        </div>
        <div className="mt-40">
          <Banner icon={CircleInformationMajor}>
            <p>Please write to us at <span className="emailLink">support@mlveda.com</span> if you have any queries</p>
          </Banner>
        </div>
      </Card> */}

      <div className="welcome-footer-btn-main">
        <Button primary onClick={handleClick} disabled={apiCalled} loading={apiCalled}>Start installation</Button>
      </div>

      <MCPFooterHelp />

      {/* <OnboardingFooter
        leftBtn={() => { }}
        rightBtn={() => (<Button
          primary
          onClick={handleClick}
        >
          Install
        </Button>)} /> */}


          {toastMarkup}

    </Layout1>
  );
};

Welcome.propTypes = {

};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    error: state.onboarding.error
  }
}
const mapDispatchToProps = {
  updateOnboarding
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Welcome);
