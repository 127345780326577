export const FORM_MODE = {
  ADD:"ADD",
  EDIT:"EDIT",
  VIEW:"VIEW"
}

export const OnboardingStatus = {
  ONBOARDING_STARTED:'onboardingStarted',
  ONBOARDING_COMPLETED:'onboardingCompleted',
  SETUP_COMPLETED:'setupCompleted',
  SETUP_STARTED: 'setupStarted'
}