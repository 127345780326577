import { GET_GLOBAL_MARKETS_BEGIN, GET_GLOBAL_MARKETS_SUCCESS, GET_GLOBAL_MARKETS_FAILURE } from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    globalMarketOptions: [],
    countryOptions: [],
    currencyOptions: [],
    error: {}
}

const globalMarkets = (state = initialState, action) => {
    switch (action.type) {
        case GET_GLOBAL_MARKETS_BEGIN:
            return { ...state, isLoading: true, error: {} };
        case GET_GLOBAL_MARKETS_SUCCESS:
            // const tempCt = action.payload.globalMarkets.filter((c)=>c.regionType!=='worldwide').map((gm) => ({ value: gm.regionName, label: gm.regionName }))
            const tempCt = action.payload.globalMarkets.map((gm) => ({ value: gm.regionName, label: gm.regionName }))
            // const tempCu = action.payload.globalMarkets.map((gm) => ({ value: gm.currencyCode, label: gm.currencyName }))
            return { ...state, globalMarketOptions: action.payload.globalMarkets, countryOptions: tempCt, isLoading: false, error: {} };
        case GET_GLOBAL_MARKETS_FAILURE:
            return { ...state, error: action.payload.error, isLoading: false };
        default:
            return state;
    }
}

export default globalMarkets;