import { GET_CURRENCIES_BEGIN, GET_CURRENCIES_SUCCESS, GET_CURRENCIES_FAILURE } from '../actions/actionTypes';
import currenciesApi from "../../services/currenciesApi";

export const getCurrenciesBegin = () => {
  return { type: GET_CURRENCIES_BEGIN }
}
export const getCurrenciesSuccess = (currencies) => {
  return { type: GET_CURRENCIES_SUCCESS, payload: { currencies } }
}
export const getCurrenciesFailure = (error) => {
  return { type: GET_CURRENCIES_FAILURE, payload: { error } }
}


export const getCurrencies = () => (dispatch) => {
  dispatch(getCurrenciesBegin());

  return currenciesApi.getCurrencies()
    .then((response) => {
      dispatch(getCurrenciesSuccess(response.data));
    }).catch((error) => {
      dispatch(getCurrenciesFailure(error));
    })
}
