import { GET_EMBEDED_BLOCK_STATUS_BEGIN, GET_EMBEDED_BLOCK_STATUS_SUCCESS, GET_EMBEDED_BLOCK_STATUS_FAILURE } from '../actions/actionTypes';
import embededBlockStatusApi from "../../services/embededBlockStatusApi";

export const getEmbededBlockStatusBegin = () => {
  return { type: GET_EMBEDED_BLOCK_STATUS_BEGIN }
}
export const getEmbededBlockStatusSuccess = (embededBlockStatus) => {
  return { type: GET_EMBEDED_BLOCK_STATUS_SUCCESS, payload: { embededBlockStatus } }
}
export const getEmbededBlockStatusFailure = (error) => {
  return { type: GET_EMBEDED_BLOCK_STATUS_FAILURE, payload: { error } }
}


export const getEmbededBlockStatus = () => (dispatch) => {
  dispatch(getEmbededBlockStatusBegin());

  return embededBlockStatusApi.getEmbededBlockStatus()
    .then((response) => {
      dispatch(getEmbededBlockStatusSuccess(response.data));
      return response.data;
    }).catch((error) => {
      dispatch(getEmbededBlockStatusFailure(error));
    })
}