import React from 'react';
import PropTypes from 'prop-types';
import { Banner, Layout, Icon, Card, RadioButton, Stack } from '@shopify/polaris';
import { InventoryOption } from '../../helpers/constant';
import { CircleInformationMajor } from '@shopify/polaris-icons';


const InventoryManagement = ({ inventoryOption, onChange }) => {
  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Inventory Management"
        description="Manage inventories for different markets"
      >
        <Card sectioned>
          <Stack vertical>
            <RadioButton
              helpText={inventoryOption === "yes" ? "When a user buys n quantity of a product from any of your market, then the inventory of that product will be reduced by n quantity in each of your market's inventory." : "" }
              label={<>
                <div className="inventoryLabel">
                  <span>I want to use same inventory for all my markets as i have only one warehouse to cater all the markets</span>
                  {/* <span>
                  <Tooltip content="This is tooltip for stock sync yes">
                    <Icon source={CircleInformationMajor} color="base" />
                  </Tooltip>
                  </span> */}
                </div>
              </>}
              checked={inventoryOption === "yes"}
              id={"yes"}
              name="inventoryOption"
              onChange={onChange}
            />
            <RadioButton
              label={<>
                <div className="inventoryLabel">
                  <span>I want to keep the inventories of each market separate</span>
                  {/* <span>
                    <Tooltip content="This is tooltip for stock sync no">
                      <Icon source={CircleInformationMajor} color="base" />
                    </Tooltip>
                  </span> */}
                </div>
              </>}
              id={"no"}
              name="inventoryOption"
              checked={inventoryOption === "no"}
              onChange={onChange}
            />
          </Stack>
          <div className="mt-20"></div>
          <Banner status="info">
            <p><span className="bold-font">Important Note:</span> The total inventory of each product will be updated to that product's current inventory multiplied by the number of markets you are selling. Contact us at support@mlveda.com if you have any queries.</p>
          </Banner>
        </Card>
      </Layout.AnnotatedSection>
    </Layout>
  );
};



export default InventoryManagement;