import React, { useState, useCallback, useEffect } from "react";
import axios from 'axios';
import { default as OnboardingLayout } from "../../../components/onboarding/Layout";
import Title from "../../../components/onboarding/title";
import { connect } from "react-redux";
import {
  Card,
  Frame,
  Toast,
  Button,
  RadioButton,
  Labelled,
  Stack,
  Modal,
  TextContainer,
  TextField,
  Link,
  Icon,
  List,
} from "@shopify/polaris";
import Divider from "../../../components/onboarding/Divider";
import {
  updateOnboarding,
  getOnboarding,
} from "../../../redux/actions/onboardingActions";
import { OnboardingStatus } from "../../../constants/common";
import MCPFooterHelp from "../../../components/common/MCPFooterHelp";
import Styles from "./styles.module.scss";
import onboardingApi from "../../../services/onboardingApi";

const StoreIntegrationContainer = ({
  updateOnboarding,
  getOnboarding,
  ...props
}) => {
  const { error, onboarding, isLoading } = props;
  const [isShowErrorToast, setIsShowErrorToast] = useState(false);
  const [isShowCopyToast, setIsShowCopyToast] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [failedValidationModalOpen, setFailedValidationModalOpen] =
    useState(false);
  const [successValidationModalOpen, setSuccessValidationModalOpen] =
    useState(false);

  useEffect(() => {
    if (error && error.error) {
      if (error.error !== "Invalid Session") {
        setIsShowErrorToast(true);
        setApiCalled(false);
      }
    }
  }, [error]);

  const redirectToStep1 = () => {
    const toBeUpdatedData = {
      currentStepNumber: 1,
      status: OnboardingStatus.ONBOARDING_STARTED,
    };
    // Dispatch the action to update the onboarding detail
    updateOnboarding(toBeUpdatedData);
  };

  const checkValidation = async () => {
    setApiCalled(true);
    try {
      const data = await onboardingApi.validateACSIntegration();
      if(data.data.isStoreIntegrationValid) {
        setSuccessValidationModalOpen(true);
      } else {
        setFailedValidationModalOpen(true);
      }
      setApiCalled(false);
    } catch(err) {
      setIsShowErrorToast(true);
      setApiCalled(false);
    }
  };

  const finishOnboarding = async () => {
    setApiCalled(true);
    if(props.embededBlockStatus === "Enabled") {
      const toBeUpdatedData = {
        currentStepNumber: 3,
        status: OnboardingStatus.SETUP_COMPLETED,
      };
      await updateOnboarding(toBeUpdatedData);
    } else {
      const toBeUpdatedData = {
        currentStepNumber: 3,
      };
      // Dispatch the action to update the onboarding detail
      await updateOnboarding(toBeUpdatedData);
    }
  };

  const copyText = (selectedId) => {
    const copyText = document.getElementById(selectedId);
    copyText.select();
    document.execCommand("copy");
    setIsShowCopyToast(true);
  };

  return (
    <OnboardingLayout>
      {/* Show current step of user */}
      {/* <StepIndicator
                totalSteps={2}
                curStep={2}
            /> */}

      {/* Show the onboarding title */}
      <Labelled
        label={<Title displayText="Step 2: Update your currency settings" />}
        helpText="Please follow the below instructions."
      ></Labelled>

      <Divider />

      {/* Show onboarding Body card */}
      <Card sectioned>
        <List type="number">
          <List.Item>
            Click on this{" "}
            <Link
              url={`https://${props.auth.storeDetails.myshopify_domain}/admin/settings/general`}
              external
            >
              store details
            </Link> (It will open in new tab)
          </List.Item>
          <List.Item>Go to the Store currency section (second section from last)</List.Item>
          <List.Item>Click on Change formatting</List.Item>
          <List.Item>
            Update HTML with currency field with the below value
            <div className="mt-10 copyMainField">
              <TextField
                id="moneyFormatWithCurrencyCopy"
                label="HTML with currency"
                readOnly
                autoComplete="off"
                value={`${onboarding.moneyFormatWithCurrency}`}
                connectedRight={
                  <Button
                    plain
                    onClick={() => copyText("moneyFormatWithCurrencyCopy")}
                  >
                    Copy
                  </Button>
                }
              />
            </div>
          </List.Item>
          <List.Item>
            Update HTML without currency field with the below value
            <div className="mt-10 copyMainField">
              <TextField
                id="moneyFormatCopy"
                label="HTML without currency"
                readOnly
                autoComplete="off"
                value={`${onboarding.moneyFormat}`}
                connectedRight={
                  <Button plain onClick={() => copyText("moneyFormatCopy")}>
                    Copy
                  </Button>
                }
              />
            </div>
          </List.Item>
          <List.Item>Click on Save button in the Shopify page</List.Item>
        </List>

        <div className={Styles["dotted-partition"]}> </div>

        <div className={Styles["contact-us"]}>
          If you are facing any problem then contact us in the chat box or send an email to{" "}
          <span className="emailLink notranslate">
            support@mlveda.com
          </span>{" "}
          and we will revert you back within 24 hours.
        </div>
      </Card>
      <div className="onboarding-footer-btn-main">
        <Stack distribution="fillEvenly">
          <Button onClick={redirectToStep1} fullWidth>
            Back
          </Button>
          <Button
            fullWidth
            loading={apiCalled}
            disabled={apiCalled}
            primary
            onClick={checkValidation}
          >
            Next
          </Button>
        </Stack>
      </div>
      {/* <OnboardingFooter
                leftBtn={() => <Button onClick={redirectToStep1}>Back</Button>}
                rightBtn={() => (<Button
                    primary
                    onClick={finishOnboarding}
                >
                    Done
                </Button>)} /> */}
      {isShowErrorToast && (
        <Frame>
          <Toast
            error
            duration={1500}
            content={"Something went wrong!"}
            onDismiss={() => {
              setIsShowErrorToast(false);
            }}
          />
        </Frame>
      )}

      {isShowCopyToast && (
        <Frame>
          <Toast
            duration={1500}
            content={"Copied"}
            onDismiss={() => {
              setIsShowCopyToast(false);
            }}
          />
        </Frame>
      )}

      <MCPFooterHelp />

      <Modal
        open={failedValidationModalOpen}
        onClose={() => setFailedValidationModalOpen(false)}
        title={`Failed`}
        primaryAction={{
          content: "OK",
          onAction: () => setFailedValidationModalOpen(false),
        }}
      >
        <Modal.Section>
          <div className={Styles["noticeDiv-store"]}>
            <div className={Styles["importantIcon-store"]}>!</div>
          </div>

          <div>
            Currency settings are not properly updated. Please follow the
            steps again. In case you are facing any issues please send an
            email to{" "}
            <span className="emailLink notranslate">support@mlveda.com</span>{" "}
            and we will revert you back within 24 hours.
          </div>
        </Modal.Section>
      </Modal>

      <Modal
        open={successValidationModalOpen}
        onClose={() => setSuccessValidationModalOpen(false)}
        title={`Success`}
        // primaryAction={{
        //   content: "OK",
        //   onAction: () => setSuccessValidationModalOpen(false),
        // }}
      >
        <Modal.Section>
          <div className={Styles["success-main"]}>
            <svg
              version="1.1"
              className={Styles["success-svg"]}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
            >
              <circle
                className={`${Styles["path"]} ${Styles["circle"]}`}
                fill="none"
                stroke="#04cea4"
                strokeWidth="6"
                strokeMiterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <polyline
                className={`${Styles["path"]} ${Styles["check"]}`}
                fill="none"
                stroke="#04cea4"
                strokeWidth="6"
                strokeLinecap="round"
                strokeMiterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
            <div className={Styles["success-msg"]}>
              Multi Country Pricing is setup successfully!
            </div>
          </div>
          <div className={Styles["empower-msg"]}>
            <span className="font-weight-600">Awesome!</span> Multi County Pricing app has been setup successfully on the store now. Let's just enable the app on the store in the next step and then you will be able to set prices for your products.
            <div className="mt-25 text-align-center">
              <Button primary onClick={finishOnboarding} loading={apiCalled} disabled={apiCalled}>Next</Button>
            </div>
          </div>
          {/* <div className={Styles["success-notice"]}>
            If you find any issue, please send an email to{" "}
            <span className="emailLink notranslate">support@mlveda.com</span>{" "}
            and we will get back to you within 24 hours.
          </div> */}
        </Modal.Section>
      </Modal>
    </OnboardingLayout>
  );
};

StoreIntegrationContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    error: state.onboarding.error,
    auth: state.auth,
    onboarding: state.onboarding.onboarding,
    embededBlockStatus: state.embededBlockStatus.status
    // isLoading: state.onboarding.isLoading
  };
};
const mapDispatchToProps = {
  updateOnboarding,
  getOnboarding,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreIntegrationContainer);
