import APIService from "./APIService";

const ONBOARDING_END_POINT = process.env.REACT_APP_ADMIN_API_ENDPOINT + "onboardings";

export default {
  getOnboarding() {
    return APIService.get(ONBOARDING_END_POINT)
  },
  updateOnboarding(putData) {
    return APIService.put(ONBOARDING_END_POINT, putData)
  },
  createOnboarding(postData) {
    return APIService.post(ONBOARDING_END_POINT, postData)
  },
  deleteOnboarding(id) {
    return APIService.delete(ONBOARDING_END_POINT)
  },
  validateACSIntegration(){
    return APIService.get(ONBOARDING_END_POINT + '/validate');
  }
}