
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import AppRoot from '../components/app-root/AppRoot';
import { getStoreDetailsBegin } from '../redux/actions/authActions';
import { connect } from 'react-redux';
import ScrollTop from '../components/common/ScrollTop';


const AppRouter = ({ isAuthenticated, ...props }) => {
    return (
      <Router >
        <ScrollTop>
          <Switch>
              {/* Take user this route if authentication successfull */}
              <Route exact path="/" component={(props) => {return  <Redirect to="/app" />}} />
              {isAuthenticated && <Route path="/app" component={(props) => <AppRoot {...props} />} />}

          </Switch>
          </ScrollTop>
      </Router>
    )
}


const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
    }
}

const mapDispatchToProps = {
    getStoreDetailsBegin
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppRouter);
