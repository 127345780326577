import React from 'react';
import PropTypes from 'prop-types';
import Styles from './styles.module.scss';
import { Button, Heading, Banner, Select, TextContainer, Modal, Autocomplete, Stack, Tag, Labelled, TextStyle } from '@shopify/polaris';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import { FormMode } from '../../helpers/constant';

const MarketForm = ({ defaultCountryOptions, onSave, formMode, onCloseForm, marketForm, onFormStateChange, 
  isWarningModelOpen, openModal, closeModal, tmpSetCurrency, handleDeleteRegion, openConfirmationDelete, isConfirmationModalOpen, 
  timer, closeConfirmationModal, deleteMarketApiCalled, onSaveApiCalled, setCurrencyApiCalled, setCurrencyOptions, displayCurrencyApiCalled, 
  displayCurrencyOptions, updateDisplayCurrencyText,displayCurrencyInputValue, removeDisplayCurrencies, managedBySPBanner, supportedCheckoutCurrencies, isROWAvailable }) => {

    // ENable the save button if all options are selected
    const isSaveBtnEnabled = marketForm.regionName && marketForm.setCurrencyCode && marketForm.displayCurrencyCodes && marketForm.displayCurrencyCodes.length > 0;

    const titleMarkup = formMode === FormMode.ADD ? 'Create a new market' : `Market: ${marketForm.regionName}`

    const textField = (
      <Autocomplete.TextField
        onChange={updateDisplayCurrencyText}
        value={displayCurrencyInputValue}
        placeholder="Select display currencies"
      />
    );

    const tagsMarkup = marketForm.displayCurrencyCodes.map((option) => {
      return (
        <Tag key={`option${option}`} onRemove={removeDisplayCurrencies(option)}>
          {option}
        </Tag>
      );
    });

    const fetchSupportedCurrencies = () => {
      const supportedCurrencies = [];
      for(let c of marketForm.displayCurrencyCodes) {
        if(supportedCheckoutCurrencies.indexOf(c) >= 0) {
          supportedCurrencies.push(c);
        }
      }
      return supportedCurrencies;
    }

    return (
        <>
        <div className={`${Styles['backdrop']}`}></div>
            <div className={Styles['container']}>
                <div className={`${Styles['header']} align-items-center d-flex justify-content-between`}>
                    <Heading element="h1">{titleMarkup}</Heading>
                    <div>
                        <Button onClick={onCloseForm} plain icon={CancelSmallMinor}></Button>
                    </div>
                </div>
                <div className={`${Styles['body']} marketForm-main`}>
                    {formMode === FormMode.ADD && <div className="mb-35">
                      <Labelled
                          label={
                            <>
                              <div className='Polaris-Heading'>Choose the country/continent</div>
                              {/* <TextStyle variation='subdued'>
                                  <span className='mediumFontSized plainFonts'>Choose the country/continent where you want to sell your products at different prices</span>
                                </TextStyle> */}
                            </>
                          }
                        >
                        </Labelled>

                        <div className="row">
                            <div className="cl-12">
                                <Select
                                    placeholder="Select"
                                    options={defaultCountryOptions}
                                    onChange={(e) => { onFormStateChange('regionName', e) }}
                                    value={marketForm.regionName}
                                />
                            </div>
                        </div>
                    </div>}
                    <div className="mb-35">
                      <Labelled
                          label={
                            <>
                              <div className='Polaris-Heading'>Choose the currency in which you want to set the prices</div>
                              {/* <TextStyle variation='subdued'>
                                  <span className='mediumFontSized plainFonts'>You can use your store currency or the currencies that are actually used in your
                                  target country or continent you selected above</span>
                                </TextStyle> */}
                            </>
                          }
                        >
                        </Labelled>
                        <div className="row">
                            <div className="cl-12">
                                <Select
                                    key="setCurrency"
                                    placeholder="Select"
                                    options={setCurrencyOptions}
                                    onChange={(e) => { formMode === FormMode.ADD ? onFormStateChange('setCurrencyCode', e) : openModal(e) }}
                                    value={marketForm.setCurrencyCode}
                                    disabled={(formMode === FormMode.EDIT && marketForm.isPrimaryMarket) || setCurrencyApiCalled}                                    />
                            </div>
                        </div>
                    </div>
                    <div className="mb-35">
                    <Labelled
                          label={
                            <>
                              <div className='Polaris-Heading'>Choose the currency in which you want your customers to see the prices</div>
                              {/* <TextStyle variation='subdued'>
                                  <span className='mediumFontSized plainFonts'>You can use your store currency or the currencies that are actually used in your
                                  target country ot region you selected above</span>
                                </TextStyle> */}
                            </>
                          }
                        >
                        </Labelled>

                        <div className="row">
                            <div className="cl-12">
                              {(!(marketForm.isPrimaryMarket && !isROWAvailable) && marketForm.regionType === "country") && <div>
                              <Select
                                    key="displayCurrencyCodes"
                                    placeholder="Select"
                                    options={displayCurrencyOptions}
                                    onChange={(e) => { onFormStateChange('displayCurrencyCodes', [e]) }}
                                    value={marketForm.displayCurrencyCodes[0]}
                                    disabled={displayCurrencyApiCalled} />
                              </div>}
                              {((marketForm.isPrimaryMarket && !isROWAvailable) || marketForm.regionType !== "country") && <div>
                                <TextContainer>
                                  <Stack>{tagsMarkup}</Stack>
                                </TextContainer>
                                <br />
                                <Autocomplete
                                  allowMultiple
                                  options={displayCurrencyOptions}
                                  selected={marketForm.displayCurrencyCodes}
                                  textField={textField}
                                  onSelect={(e) => { onFormStateChange('displayCurrencyCodes', e)}}
                                  listTitle="Display currencies"
                                  disabled={displayCurrencyApiCalled}
                                />
                              </div>}
                            </div>
                        </div>
                    </div>

                    {marketForm.regionName && <Banner status="info">
                    {fetchSupportedCurrencies().length > 0 && fetchSupportedCurrencies().length !== marketForm.displayCurrencyCodes.length && <span>As per your checkout and payment gateway settings, customers browsing website in <span className="bold-font">{fetchSupportedCurrencies().join(", ")}</span> can pay directly. Others will pay in your store currency.</span>}
                    {fetchSupportedCurrencies().length > 0 && fetchSupportedCurrencies().length === marketForm.displayCurrencyCodes.length && <span>As per your checkout and payment gateway settings, customers browsing website in <span className="bold-font">{fetchSupportedCurrencies().join(", ")}</span> can pay directly.</span>}
                    {fetchSupportedCurrencies().length <= 0 && <span>As per your checkout and payment gateway settings, customers can only pay in your store currency.</span>}
                    </Banner>}

                    {/* {managedBySPBanner && <Banner>
                      Display currency is managed by Shopify Payments
                    </Banner>} */}
                </div>
                <div className={`${Styles['footer']}`}>
                  <div className={`${Styles['subDiv']}`}>
                  {formMode === FormMode.EDIT && !marketForm.isPrimaryMarket && <div className={`${Styles['button1']} marketDeleteBtn mr-15`}>
                    <Button monochrome outline onClick={() => openConfirmationDelete()}>
                      Delete
                    </Button>
                  </div>}
                    <div className="mr-15">
                        <Button onClick={onCloseForm}> Cancel</Button>
                    </div>
                    <div className="marketformPrimaryBtn">
                        <Button loading={onSaveApiCalled} disabled={!isSaveBtnEnabled} primary onClick={onSave}> Save</Button>
                    </div>
                  </div>
                </div>
            </div>

            <Modal
              open={isWarningModelOpen}
              onClose={closeModal}
              title={"Are you sure you want to change?"}
              primaryAction={{
                content: 'Yes',
                onAction: () => {onFormStateChange('setCurrencyCode', tmpSetCurrency); closeModal()},
              }}
              secondaryActions={[
                {
                  content: 'No',
                  onAction: closeModal,
                },
              ]}
            >
              <Modal.Section>
                <TextContainer>
                  <p>
                    Changing currency used to set the prices in this dashboard will change all your product prices.
                  </p>
                </TextContainer>
              </Modal.Section>
            </Modal>

            <Modal
              open={isConfirmationModalOpen}
              onClose={closeConfirmationModal}
              title={timer > 2000 ? `Your application will freeze for ${timer/1000} seconds` : `Do you want to delete ${marketForm.regionName} market?`}
              primaryAction={{
                content: 'Yes',
                loading: deleteMarketApiCalled,
                disabled: deleteMarketApiCalled,
                onAction: () => { handleDeleteRegion() },
              }}
              secondaryActions={[
                {
                  content: 'No',
                  onAction: closeConfirmationModal,
                },
              ]}
            >
              <Modal.Section>
                <p>Are you sure want to delete this market? This will remove all the prices you set up for this market permanently. </p>
              </Modal.Section>
            </Modal>
        </>
    );
};

MarketForm.propTypes = {
    marketForm: PropTypes.object.isRequired
};

export default MarketForm;