import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";

import { composeWithDevTools } from 'remote-redux-devtools';
const initialState = {};

const middleware = [thunk];

// const enhance = composeWithDevTools({ realtime: true, host: 'localhost', port: 3000 });

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
  )
);
export default store;