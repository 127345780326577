import React from "react";
import { Layout, Card, Stack, Button, Modal } from "@shopify/polaris";

const RemoveVariants = (props) => {
  return (
    <>
      <Layout>
        <Layout.AnnotatedSection title="Reset the App to initial setup"
        description="This will remove all the market variants in your store.">
          <Card sectioned>
            <div>
              <div>Choose this option if you want to remove the prices for all your market variants. Only the prices you set on your Shopify admin will remain after this.</div>
              <div className="mt-10"></div>
              <div>
                <Button onClick={() => {props.openRemoveVariantsConfirmationModal()}}>
                  Remove market variants
                </Button>
              </div>
              <div className="mt-20"></div>
              <div>Depending on the number of products on your store, the app dashboard will be frozen for sometime</div>
            </div>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      <Modal
        open={props.isRemoveVariantConfirmationModalOpened}
        onClose={props.closeRemoveVariantConfirmationModal}
        title={
          props.timer > 2000
            ? `Your application will freeze for ${props.timer / 1000} seconds`
            : "Are you sure?"
        }
        primaryAction={{
          content: "Yes",
          loading: props.removeVariantApiCalled,
          disabled: props.removeVariantApiCalled,
          onAction: () => {
            props.removeVariants();
          },
        }}
        secondaryActions={[
          {
            content: "No",
            onAction: props.closeRemoveVariantConfirmationModal,
          },
        ]}
      >
        <Modal.Section>
          <p>Are you sure want to remove all the market variants?</p>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default RemoveVariants;
