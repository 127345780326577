import React, { useEffect, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import classes from './styles.module.scss';
import { Toast, Frame, Loading } from '@shopify/polaris';

const FAQContainer = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [isShowErrorToast, setIsShowErrorToast] = useState(false);
  const [html, setHTML] = useState('');

  useEffect(() => {
    axios.get('https://wordpress-241355-3176562.cloudwaysapps.com/wp-json/wp/v2/posts/5753?password=himalaya')
      .then((htmlData) => {
        setHTML(htmlData.data.content.rendered);
      }).catch(() => {
        setIsShowErrorToast(true);
      }).finally(() => {
        setIsLoading(false);
      })
  }, []);

  return (
    <div className={classes['faq-conatiner']}>
      {isLoading && <div style={{ height: '100px' }}>
          <Frame>
            <Loading />
          </Frame>
        </div>}
      {!isLoading && <div className={classNames(classes.root, 'entry-content')}>
        <body
          className='entry-content'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {isShowErrorToast && <Toast content="Something went wrong" onDismiss={setIsShowErrorToast(false)} />}
      </div>}
    </div>
  )
}

export default FAQContainer;