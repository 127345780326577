export const FormMode = {
  ADD:"ADD",
  EDIT:"EDIT",
  VIEW:"VIEW",
  DELETE: "DELETE"
}

export const InventoryOption = {
  SAME_FOR_EACH_MARKET: "SAME_FOR_EACH_MARKET",
  SEPARATE_FOR_EACH_MARKET: "SEPARATE_FOR_EACH_MARKET"
}