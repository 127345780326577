import React from "react";
import { List, Link } from "@shopify/polaris";
import AppEmbed from "../../../assets/images/AppEmbed.png";
import Styles from "./styles.module.scss";

const EmbededAppInstructions = (props) => {
  return (
    <div>
      <List type="number">
        <List.Item>
          Click{" "}
          <Link
            url={`https://${props.shop}/${process.env.REACT_APP_EXTENSION_URL}`}
            external
          >
            here
          </Link>{" "}
          (It will open in new tab)
        </List.Item>
        <List.Item>
          Click on the Save button which is there on the top right corner of the page opened in step 1 (see below image)
          <div>
            <img className={Styles["img-block"]} src={AppEmbed} alt="App Embed" />
          </div>
        </List.Item>
      </List>
      <div className={Styles["dotted-partition"]}> </div>

      <div className={Styles["contact-us"]}>
        If you are facing any problem then please send an email to{" "}
        <span className="emailLink notranslate">support@mlveda.com</span> and we
        will revert you back within 24 hours.
      </div>
    </div>
  );
};

export default EmbededAppInstructions;
