import React, { useEffect, useState } from 'react';

import '@shopify/polaris/dist/styles.css';
import './styles/base.scss';

import AppRouter from './routers/AppRouter';
import { getStoreDetails, getStoreDetailsBegin } from './redux/actions/authActions';
import { getCurrencies } from './redux/actions/currenciesActions';
import { getSettings } from './redux/actions/settingsActions';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import InvalidSession from './components/InvalidSession';
import { hotjar } from 'react-hotjar';


const App = ({ getStoreDetails, getCurrencies, getSettings, ...props }) => {
  const [isAppLoading, setIsAppLoading] = useState(true);
  const [invalidSession, setInvalidSession] = useState(false);

  useEffect(() => {
    const token = getParameterByName('token');
    if (token != null) {
      sessionStorage.setItem("token",token)
      // cookies.set('token', token, { path: '/' });
    } else if(process.env.REACT_APP_ENVIORNMENT !== 'local' && !sessionStorage.getItem("token")) {
      setInvalidSession(true);
    }

    getCurrencies();
    getSettings().then(() => {
      getStoreDetails().then((storeData) => {
        if(storeData.myshopify_domain) {
          if(storeData.myshopify_domain.substring(0, 7) !== "mcptest") {
            hotjar.initialize(2369627, 6);
          }
        }
        const fresh_install = getParameterByName('fresh_install');
        if(fresh_install === "1") {
          const cookies = new Cookies();
          cookies.remove(`${storeData.myshopify_domain}_mcp_freezeTime`, { path: '/' });
        }

        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "9a70b14e-b8db-48b2-8773-95eb66b91cee";
        (function () {
          var d = document;
          var s = d.createElement("script");
          s.src = "https://client.crisp.chat/l.js";
          s.async = 1;
          d.getElementsByTagName("head")[0].appendChild(s);
          window.$crisp.push(["set", "session:segments", [["mcp"]]]);
          if(storeData.myshopify_domain) {
            window.$crisp.push(["set","session:data",["storename", storeData.myshopify_domain]]);
          }
        })();

        setIsAppLoading(false)
      });
    })
  }, [])

  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  return (
    <>
      { (!props.auth.isLoading && !isAppLoading) ? <AppRouter /> : null}
      {(!props.auth.isLoading && !isAppLoading) && (!props.auth.isAuthenticated || invalidSession) && <InvalidSession storeId={(props.auth && props.auth.storeDetails && props.auth.storeDetails.myshopify_domain) || ""} />}
    </>
  );
}


const mapStateToProps = (state) => ({
  auth: state.auth,
})

const mapDispatchToProps = {
  getStoreDetails,
  getCurrencies,
  getSettings
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
