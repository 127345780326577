import { GET_ONBOARDING_BEGIN, GET_ONBOARDING_SUCCESS, GET_ONBOARDING_FAILURE, UPDATE_ONBOARDING_BEGIN, UPDATE_ONBOARDING_FAILURE } from '../actions/actionTypes';
import onboardingApi from "../../services/onboardingApi";
import { UPDATE_ONBOARDING_SUCCESS } from './actionTypes';

export const getOnboardingBegin = () => {
    return { type: GET_ONBOARDING_BEGIN }
}
export const getOnboardingSuccess = (onboarding) => {
    return { type: GET_ONBOARDING_SUCCESS, payload: { onboarding } }
}
export const getOnboardingFailure = (error) => {
    return { type: GET_ONBOARDING_FAILURE, payload: { error } }
}

export const updateOnboardingBegin = () => {
    return { type: UPDATE_ONBOARDING_BEGIN }
}
export const updateOnboardingSuccess = (onboarding) => {
    return { type: UPDATE_ONBOARDING_SUCCESS, payload: { onboarding } }
}
export const updateOnboardingFailure = (error) => {
    return { type: UPDATE_ONBOARDING_FAILURE, payload: { error } }
}


export const getOnboarding = () => (dispatch) => {
    dispatch(getOnboardingBegin());
    return onboardingApi.getOnboarding()
        .then((response) => {
            dispatch(getOnboardingSuccess(response.data));
            return response.data
        }).catch((error) => {
            dispatch(getOnboardingFailure(error));
        })
}

export const createOnboarding = () => (dispatch) => {
    return
}

export const updateOnboarding = (onboarding) => (dispatch) => {
    // dispatch(updateOnboardingBegin());

    return onboardingApi.updateOnboarding(onboarding)
        .then((response) => {
            dispatch(updateOnboardingSuccess(response.data));
        }).catch((error) => {
            dispatch(updateOnboardingFailure(error));
        })

}


export const deleteOnboarding = () => (dispatch) => {
    return
}
