import React, { useState, useEffect } from "react";
import { default as OnboardingLayout } from '../../../components/onboarding/Layout';
import Title from "../../../components/onboarding/title";
import Divider from "../../../components/onboarding/Divider";
import {
  Card,
  Button,
  Toast,
  Frame,
  Stack,
  Labelled,
} from "@shopify/polaris";
import {
  updateOnboarding,
  getOnboarding,
} from "../../../redux/actions/onboardingActions";
import { getEmbededBlockStatus } from "../../../redux/actions/embededBlockStatusActions";
import MCPFooterHelp from "../../../components/common/MCPFooterHelp";
import EmbededAppInstructions from "../../../components/common/EmbededAppInstruction";
import { OnboardingStatus } from "../../../constants/common";
import { connect } from "react-redux";
import embededBlockStatusApi from "../../../services/embededBlockStatusApi";

const AppEmbededContainer = ({ updateOnboarding, getOnboarding, getEmbededBlockStatus, ...props }) => {
  const { error, onboarding, isLoading } = props;
  const [isShowErrorToast, setIsShowErrorToast] = useState(false);
  const [isShowEmbedBlockErrorToast, setIsShowEmbedBlockErrorToast] = useState(false);
  const [onboardingUpdateApiCalled, setOnboardingUpdateApiCalled] =
    useState(false);

  useEffect(() => {
    if (error && error.error) {
      if (error.error !== "Invalid Session") {
        setIsShowErrorToast(true);
        setOnboardingUpdateApiCalled(false);
      }
    }
  }, [error]);

  const redirectToStep2 = () => {
    const toBeUpdatedData = {
      currentStepNumber: 2,
    };
    // Dispatch the action to update the onboarding detail
    updateOnboarding(toBeUpdatedData);
  };

  const finishOnboarding = async () => {
    setOnboardingUpdateApiCalled(true);
      try {
        const data =  await embededBlockStatusApi.getEmbededBlockStatus();
        // const data = await getEmbededBlockStatus();
        if(data.data.status === "Enabled") {
          const toBeUpdatedData = {
            status: OnboardingStatus.SETUP_COMPLETED,
          };
          await updateOnboarding(toBeUpdatedData);
          await getEmbededBlockStatus();
        } else {
          setIsShowEmbedBlockErrorToast(true);
          setOnboardingUpdateApiCalled(false);
        }
      } catch(error) {
        setIsShowErrorToast(true);
        setOnboardingUpdateApiCalled(false);
      }
  };

  return (
    <OnboardingLayout>

      <Labelled 
        label={<Title displayText="Step 3: Enable the app"/>}
        helpText="Please follow the below steps.">
      </Labelled>

      <Divider />

      <Card sectioned>
        <EmbededAppInstructions
          shop={props.auth.storeDetails.myshopify_domain}
        />
      </Card>

      <div className="onboarding-footer-btn-main">
        <Stack distribution="fillEvenly">
          <Button onClick={redirectToStep2} fullWidth>
            Back
          </Button>
          <Button
            fullWidth
            loading={onboardingUpdateApiCalled}
            disabled={onboardingUpdateApiCalled}
            primary
            onClick={finishOnboarding}
          >
            Done
          </Button>
        </Stack>
      </div>

      <MCPFooterHelp />

      {isShowErrorToast && (
        <Frame>
          <Toast
            error
            duration={1500}
            content={"Something went wrong!"}
            onDismiss={() => {
              setIsShowErrorToast(false);
            }}
          />
        </Frame>
      )}

      {isShowEmbedBlockErrorToast && (
        <Frame>
          <Toast
            error
            duration={1500}
            content={"Please enable embeded block"}
            onDismiss={() => {
              setIsShowEmbedBlockErrorToast(false);
            }}
          />
        </Frame>
      )}
    </OnboardingLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.onboarding.error,
    onboarding: state.onboarding.onboarding,
    auth: state.auth,
    isLoading: state.onboarding.isLoading,
  };
};
const mapDispatchToProps = {
  updateOnboarding,
  getOnboarding,
  getEmbededBlockStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppEmbededContainer);
