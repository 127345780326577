import React from 'react';
import './Header.scss';
import { Stack, TopBar, Image, Tabs, Icon, Button, Modal, TextStyle } from '@shopify/polaris';
import { QuestionMarkMajor, GlobeMajor, SettingsMajor, EditMajor, TroubleshootMajor, CalendarMajor } from '@shopify/polaris-icons';
import { useState, useCallback } from 'react';
// import Translate from '../TranslateContainer';
import reviewImg from '../../assets/images/reviewImg.png';
import logo from '../../assets/images/logo.png';
import cryingFaceImg from '../../assets/images/cryingFace.png';
import smilingFaceWithHeartEyesImg from '../../assets/images/smilingFaceWithHeartEyes.png';
import foldedHandsImg from '../../assets/images/folded-hands.png';
import ceoImg from '../../assets/images/CEO.png';
import { useEffect } from 'react';
import { connect } from "react-redux";
import { OnboardingStatus } from '../../constants/common';

const Header = props => {
  const { onboarding } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isNeedHelpModalOpen, setIsNeedHelpModalOpen] = useState(false);
  const tabs = [
    {
      id: 0,
      icon: GlobeMajor,
      text: 'MARKETS',
      url: '/app/markets',
    },
    {
      id: 1,
      icon: EditMajor,
      text: 'PRICES',
      url: '/app/product-prices',
    },
    {
      id: 2,
      icon: SettingsMajor,
      text: 'SETTINGS',
      url: '/app/settings',
    },
    {
      id: 3,
      icon: TroubleshootMajor,
      text: 'SUPPORT',
      url: '/app/support',
    },
    {
      id: 4,
      icon: QuestionMarkMajor,
      text: 'FAQ',
      url: '/app/faq',
    },
    {
      id: 5,
      icon: CalendarMajor,
      text: 'BOOK A DEMO',
      url: 'https://calendly.com/mlveda-team/support',
    },
  ];

  // useEffect(() => {
  //   checkPathName();
  // }, []);

  useEffect(() => {
    if(onboarding.status !== OnboardingStatus.ONBOARDING_STARTED) {
      checkPathName();
    }
  }, [props.location])

  const checkPathName = () => {
    const { pathname } = props.location;
    let selectedTab = 0;
    if (pathname.includes('/app/markets')) {
      selectedTab = 0;
    } else if (pathname.includes('/app/product-prices')) {
      selectedTab = 1;
    } else if (pathname.includes('/app/settings')) {
      selectedTab = 2;
    } else if (pathname.includes('/app/support')) {
      selectedTab = 3;
    } else {
      selectedTab = 4;
    }
    setSelectedTabIndex(selectedTab);
  }

  const handleTabChange = (selectedTabIndex) => {
    if(selectedTabIndex === 5) {
      window.open("https://calendly.com/mlveda-team/support", "_blank");
    } else {
      if(selectedTabIndex === 4 || selectedTabIndex === 3) {
        setSelectedTabIndex(selectedTabIndex);
        props.history.push(tabs[selectedTabIndex].url);
      }
      else if(!props.isFroze) {
        setSelectedTabIndex(selectedTabIndex);
        props.history.push(tabs[selectedTabIndex].url);
      }
    }
  };

  const closeReviewModal = () => {
    setIsReviewModalOpen(false);
  }

  const openReviewModal = () => {
    setIsReviewModalOpen(true);
  }

  const closeHelpModal = () => {
    setIsNeedHelpModalOpen(false);
  }

  const openHelpModal = () => {
    setIsNeedHelpModalOpen(true);
  }

  const redirectToReview = () => {
    setIsReviewModalOpen(false);
    window.open(
      'https://apps.shopify.com/country-pricing-auto-switch#modal-show=ReviewListingModal',
      '_blank',
    );
  }
  return (
    <div>
      {onboarding.status !== OnboardingStatus.ONBOARDING_STARTED ? <div className={'appTopBar'}>
        <TopBar
          searchField={
            <Tabs
              selected={selectedTabIndex}
              tabs={tabs.map((tab, i) => ({
                // url: tab.url,
                id: tab.id,
                content: (
                  <div className={(props.isFroze && tab.id !== 3 && tab.id !== 4 && tab.id !== 5) ? "freezeTab" : ""}>
                    <Stack
                      spacing='tight'
                      distribution='center'
                      alignment='center'
                    >
                      <Icon
                        color={i === selectedTabIndex ? 'indigo' : 'black'}
                        source={tab.icon}
                      />
                      <Stack.Item>
                        {' '}
                        <span
                          className={i === selectedTabIndex ? 'selectedTabText' : ''}
                        >
                          {tab.text}
                        </span>
                      </Stack.Item>
                    </Stack>
                  </div>
                ),
              }))}
              onSelect={handleTabChange}
            />
          }
          contextControl={
            <div
              // style={{ cursor: 'pointer', height: '100%' }}
              onClick={() => !props.isFroze && props.history.push('/app/markets')}
            >
              <Stack
                alignment='center'
                spacing='tight'
                distribution='leading'
              >
                <Stack.Item>
                  <Image
                    alt=''
                    source={logo}
                    className={'setupContainer-logo'}
                  />
                </Stack.Item>
                <Stack.Item>
                  <span className={'setupContainer__titleText boldFonts largeFontSized'}>
                  Multi Country Pricing
                        </span>
                </Stack.Item>
              </Stack>
            </div>
          }
          secondaryMenu={
            <span
              onClick={() => !props.isFroze && openReviewModal()}
              className={'reviewIcon'}
            >
              <Image source={reviewImg} />
            </span>
          }
          // userMenu={<Translate />}
        />
      </div> : <div className='setupContainer__appTopBar__container'>
          <TopBar
            secondaryMenu={
              <Button primary plain onClick={openHelpModal}>Help</Button>
            }
            // userMenu={
            //   <Translate />
            // }
            searchField={
              onboarding.currentStepNumber > 0 ? <Stack vertical spacing='tight'>
                <span>Step {onboarding.currentStepNumber} of 3</span>
                <div className='setupContainer__appTopBar__progressBar__container'>
                  <div
                    className='setupContainer__appTopBar__progressBar'
                    style={{ width: `${(onboarding.currentStepNumber * 100) / 3}%` }}
                  ></div>
                </div>
              </Stack> : <></>
            }
            contextControl={<div className='topBarLogo__container'>
              <Stack
                alignment='center'
                wrap={false}
                spacing='tight'
                distribution='leading'
              >
                <Stack.Item>
                  <Image alt='' source={logo} className='setupContainer__appTopBar__logo' />
                </Stack.Item>
                <Stack.Item>
                  <h2 className='setupContainer__appTopBar__titleText'>Multi Country Pricing</h2>
                </Stack.Item>
              </Stack>
            </div>}
          />
        </div>}
      <Modal open={isReviewModalOpen} onClose={closeReviewModal} title={
        <Stack spacing='tight'>
          <Image style={{ width: '2.8rem' }} src={foldedHandsImg} />
          <h2>Please write a review</h2>
        </Stack>
      }>
        <Modal.Section>
          <Stack distribution='center' vertical spacing='loose'>
            <span>
            We have put in a lot of effort to create this app. It is built exclusively for Shopify store owners to increase their international sales and profit margins.
                      </span>
            <span>
              We are continuously looking to make our app better. So, please write a <TextStyle variation='strong'>genuine feedback of your experience.</TextStyle>
            </span>
            <Stack spacing='tight' vertical>
              <div className='lastStep__card__button  lastStep__card__button__yes dashboardPage__modalButton dashboardPage__modalButton__yes'>
                <Button
                  onClick={redirectToReview}
                  fullWidth
                >
                  <Stack
                    distribution='center'
                    alignment='center'
                    spacing='tight'
                  >
                    <Image
                      className='lastStep__card__buttonImg'
                      source={smilingFaceWithHeartEyesImg}
                    />
                    <h2>YES!</h2>{' '}
                    <span className='lastStep__card__button__text'>
                    I would like to write a review now
                              </span>
                  </Stack>
                </Button>
              </div>
              <div className='lastStep__card__button lastStep__card__button__no  dashboardPage__modalButton dashboardPage__modalButton__no'>
                <Button
                  // loading={apiCalled}
                  onClick={closeReviewModal}
                  fullWidth
                >
                  <Stack
                    distribution='center'
                    alignment='center'
                    spacing='tight'
                  >
                    <Image
                      className='lastStep__card__buttonImg'
                      source={cryingFaceImg}
                    />
                    <h2>No!</h2>{' '}
                    <span className='lastStep__card__button__text'>
                    I will review it after few days
                              </span>
                  </Stack>
                </Button>
              </div>
            </Stack>
          </Stack>
        </Modal.Section>
      </Modal>

      <Modal open={isNeedHelpModalOpen} onClose={closeHelpModal} 
      title={<span className='h2'>Talk to your dedicated relationship manager</span>}
      primaryAction={{
        content: 'Close',
        onAction: () => { closeHelpModal() },
      }}>
        <Modal.Section>
          <Stack vertical spacing='loose' alignment='center'>
            <Stack vertical spacing='none' alignment='center'>
              <Image
                source={ceoImg}
                className='supportPage__ceoImg'
                alt='CEO Image'
              />
              <h2>Reshma Bhagwani</h2>
              <span>Shopify Expert</span>
            </Stack>
            <div className='centeredText'>
              <span>
                Send us a mail at{' '}
                <span className='notranslate emailLink'>support@mlveda.com</span>{' '}
                          and our awesome support team will get back to you within
                          24 hours.
                        </span>
            </div>
            <Button primary outline external url='https://calendly.com/mlveda-team/'>Book a meeting</Button>
          </Stack>
        </Modal.Section>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    onboarding: state.onboarding.onboarding,
    isFroze: state.settings.isFroze
  }
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

// export default Header;