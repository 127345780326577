import React from 'react';
import { Modal, TextField, Button, Card, Stack, Select, Layout, Badge, Icon, Tooltip } from '@shopify/polaris';
import { CircleInformationMajor } from '@shopify/polaris-icons';

const RoundingRules = ({ roundings, saveRoundingRule, showAll, setShowAll, currentEditRule, editModalOpen, openModal, closeModal, handleSelectChange, 
  roundingApiCalled, storeCurrency, isConfirmationModalOpened, timer, openConfirmationModal, closeConfirmationModal }) => {
  return (
    <div className="rounding-rule-container">
      <Layout>
        <Layout.AnnotatedSection
          title="Currency Rounding Rules"
          description="Manage the rounding rules for different currencies"
        >

          {roundings.map((rounding, index) => {
            return (
              showAll ? (<Card sectioned key={rounding.currency}>
                <Stack wrap={false}>
                  <Stack.Item fill>
                    <div>{rounding.name} ({rounding.currency}){rounding.currency === storeCurrency && <span className="ml-10"><Badge status="success">Store currency</Badge></span>}</div>
                    {rounding.shopify_enabled && <div className="mamagedBySP">This currency's rounding rule is managed by Shopify Payments
                      {/* <span>
                        <Tooltip content="This is tooltip for rounding rule managed by shopify">
                          <Icon source={CircleInformationMajor} color="base" />
                        </Tooltip>
                      </span> */}
                    </div>}
                  </Stack.Item>
                  <Stack.Item><TextField value={rounding.textFieldSelected} disabled /></Stack.Item>
                  <Stack.Item>
                    <Button plain disabled={rounding.shopify_enabled || (rounding.currency === storeCurrency)} onClick={() => { openModal(rounding) }}>Edit</Button>
                  </Stack.Item>
                </Stack>
              </Card>) : (
                  index <= 4 && <Card sectioned key={rounding.currency}>
                    <Stack wrap={false}>
                      <Stack.Item fill>
                        <div>{rounding.name} ({rounding.currency}){rounding.currency === storeCurrency && <span className="ml-10"><Badge status="success">Store currency</Badge></span>}</div>
                        {rounding.shopify_enabled && <div className="mamagedBySP">This currency's rounding rule is managed by Shopify Payments
                          {/* <span>
                            <Tooltip content="This is tooltip for rounding rule managed by shopify">
                              <Icon source={CircleInformationMajor} color="base" />
                            </Tooltip>
                          </span> */}
                        </div>}
                      </Stack.Item>
                      <Stack.Item><TextField value={rounding.textFieldSelected} disabled /></Stack.Item>
                      <Stack.Item>
                        <Button plain disabled={rounding.shopify_enabled || (rounding.currency === storeCurrency)} onClick={() => { openModal(rounding) }}>Edit</Button>
                      </Stack.Item>
                    </Stack>
                  </Card>
                )
            )
          })}

          {roundings && roundings.length > 5 && <Stack distribution='center'>
            <Button
              plain
              onClick={() => { setShowAll(!showAll) }}
            >
              Show {showAll ? 'less' : 'more'}
            </Button>
          </Stack>}
        </Layout.AnnotatedSection>
      </Layout>

      {currentEditRule && <Modal
        open={editModalOpen}
        onClose={closeModal}
        title={`Change rounding for ${currentEditRule.currency}`}
        primaryAction={{
          content: 'Save',
          onAction: () => { openConfirmationModal() },
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: closeModal,
          },
        ]}
      >
        <Modal.Section>
          <Select options={currentEditRule.selectOption}
            label="Rounding"
            onChange={handleSelectChange}
            value={currentEditRule.selected} />
        </Modal.Section>
      </Modal>}

      <Modal
        open={isConfirmationModalOpened}
        onClose={closeConfirmationModal}
        title={timer > 2000 ? `Your application will freeze for ${timer/1000} seconds`: "Are you sure?"}
        primaryAction={{
          content: 'Yes',
          loading: roundingApiCalled,
          disabled: roundingApiCalled,
          onAction: () => { saveRoundingRule(currentEditRule) },
        }}
        secondaryActions={[
          {
            content: 'No',
            onAction: closeConfirmationModal,
          },
        ]}
      >
        <Modal.Section>
          <p>Are you sure want to change?</p>
        </Modal.Section>
      </Modal>
    </div>
  );
};



export default React.memo(RoundingRules);