import * as types from '../actions/actionTypes';
import marketApi from "../../services/marketApi";

export const getMarketsBegin = () => {
    return { type: types.GET_MARKETS_BEGIN }
}
export const getMarketsSuccess = (markets) => {
    return { type: types.GET_MARKETS_SUCCESS, payload: { markets } }
}
export const getMarketsFailure = (error) => {
    return { type: types.GET_MARKETS_FAILURE, payload: { error } }
}

export const createMarketBegin = () => {
    return { type: types.CREATE_MARKET_BEGIN }
}
export const createMarketSuccess = (market) => {
    return { type: types.CREATE_MARKET_SUCCESS, payload: { market } }
}
export const createMarketFailure = (error) => {
    return { type: types.CREATE_MARKET_FAILURE, payload: { error } }
}

export const updateMarketBegin = () => {
    return { type: types.UPDATE_MARKET_BEGIN }
}
export const updateMarketSuccess = (market) => {
    return { type: types.UPDATE_MARKET_SUCCESS, payload: { market } }
}
export const updateMarketFailure = (error) => {
    return { type: types.UPDATE_MARKET_FAILURE, payload: { error } }
}

export const deleteMarketBegin = () => {
  return { type: types.DELETE_MARKET_BEGIN }
}
export const deleteMarketSuccess = (market) => {
  return { type: types.DELETE_MARKET_SUCCESS, payload: { market } }
}
export const deleteMarketFailure = (error) => {
  return { type: types.DELETE_MARKET_FAILURE, payload: { error } }
}

export const getMarkets = () => (dispatch) => {
    dispatch(getMarketsBegin());

    return marketApi.getMarkets()
        .then((response) => {
            const markets = response.data;
            markets.sort((a, b) => {
              if(new Date(a.createdAt) > new Date(b.createdAt)) {
                return 1;
              } else if (new Date(a.createdAt) < new Date(b.createdAt)) {
                return -1;
              } else {
                return 0;
              };
            });
            const primaryMaket = markets.find(i => i.isPrimaryMarket === true);
            if(primaryMaket) {
              const index = markets.indexOf(primaryMaket);
              if(index > -1) {
                markets.splice(index, 1);
                markets.splice(0, 0, primaryMaket);
              }
            }

            const restOfWorld = markets.find(i => i.regionCode === "intl");
            if(restOfWorld) {
              const index = markets.indexOf(restOfWorld);
              if(index > -1) {
                markets.splice(index, 1);
                markets.splice(markets.length, 0, restOfWorld);
              }
            }
            dispatch(getMarketsSuccess(markets));
        }).catch((error) => {
            dispatch(getMarketsFailure(error));
        })

}

export const createMarket = (marketForm) => (dispatch) => {
    dispatch(createMarketBegin());

    return marketApi.createMarket(marketForm)
        .then((response) => {
            dispatch(createMarketSuccess(response.data));
            return response.data;
        }).catch((error) => {
            dispatch(createMarketFailure(error));
            return Promise.reject({ error: error });
        })
}

export const updateMarket = (marketForm) => (dispatch) => {
    dispatch(updateMarketBegin());

    return marketApi.updateMarket(marketForm.id, marketForm)
        .then((response) => {
            dispatch(updateMarketSuccess(response.data));
            return response.data;
        }).catch((error) => {
            dispatch(updateMarketFailure(error));
            return Promise.reject({ error: error });
        })
}

export const deleteMarket = (marketForm) => (dispatch) => {
  dispatch(deleteMarketBegin());

  return marketApi.deleteMarket(marketForm.id)
      .then((response) => {
          dispatch(deleteMarketSuccess(response.data));
      }).catch((error) => {
          dispatch(deleteMarketFailure(error));
      })
}