import React, { useEffect } from 'react';
import { Heading, Banner, TextContainer, Card, Link, Modal, TextField, Button, ButtonGroup, Icon, List, Toast } from '@shopify/polaris';
import {
  RiskMinor
} from '@shopify/polaris-icons';
import Styles from './styles.module.scss';
import staffInfo from '../../assets/images/staffInfo.png';
import staffPermission from '../../assets/images/staffPermission.png';
import { connect } from "react-redux";
import { updateSettings } from '../../redux/actions/settingsActions';
import productApi from '../../services/productsApi';
import FrozeAdminPanel from '../../containers/FrozeAdminPanel';
import Cookies from 'universal-cookie';

const DeleteApp = (props) => {
  const { updateSettings, error } = props;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [confirmVal, setConfirmVal] = React.useState('');
  const [randomCode, setRandomCode] = React.useState('');
  const [staffAccessModalOpen, setStaffAccessModalOpen] = React.useState(false);
  const [currentStepNumber, setCurrentStepNumber] = React.useState(1);
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const [isShowErrorToast, setIsShowErrorToast] = React.useState(false);
  const [apiCalled, setApiCalled] = React.useState(false);
  const [openFrozeAdminPanel, setOpenFrozeAdminPanel] = React.useState(false);
  const [timer, setTimer] = React.useState(0);

  useEffect(() => {
    if(error && error.error) {
      if(error.error !== "Invalid Session") {
        setIsShowErrorToast(true);
      }
    }
  }, [error]);

  useEffect(() => {
    if(props.isFroze) {
      const cookies = new Cookies();
      const isClicked = cookies.get(`${props.storeId}_mcp_delete_variant_clicked`);
      const timer = cookies.get(`${props.storeId}_mcp_delete_variant_timer`);
      setTimer(timer);
      if(isClicked === "Yes") {
        setTimeout(() => {
          setConfirmationModalOpen(true);
        }, 500)
        cookies.remove(`${props.storeId}_mcp_delete_variant_clicked`, { path: '/' });
      } else {
        cookies.remove(`${props.storeId}_mcp_delete_variant_clicked`, { path: '/' });
      }
    }
  }, [props.isFroze])

  const openModal = () => {
    setIsModalOpen(true)
    setConfirmVal('')
    setRandomCode(Math.random().toString(36).substr(2, 5).toUpperCase())
  }

  const openStaffAccessModal = () => {
    setStaffAccessModalOpen(true);
    setCurrentStepNumber(1);
  }

  const closeStaffAccessModal = () => {
    setStaffAccessModalOpen(false);
    setCurrentStepNumber(1);
  }

  const handleStepChange = (type) => {
    if(type === "increase") {
      setCurrentStepNumber(currentStepNumber + 1);
    } else {
      setCurrentStepNumber(currentStepNumber - 1);
    }
  };

  const deleteVariants = async () => {
    setApiCalled(true);
    const variants = await productApi.removeVariants().catch(err => {
      setIsShowErrorToast(true);
    });
    if(variants) {
      const cookies = new Cookies();
      cookies.set(`${props.storeId}_mcp_delete_variant_clicked`, "Yes", { path: '/' });
      const setting = await updateSettings({isFroze: true}).catch(err => {
        setIsShowErrorToast(true);
      });
      if(setting) {
        const changedTime = variants.data ? (variants.data.time_needed_in_seconds * 1000) : 86400000;
        // const changedTime = variants.data ? (10 * 1000) : 86400000;
        setTimer(changedTime);
        cookies.set(`${props.storeId}_mcp_delete_variant_timer`, changedTime, { path: '/' });
        const currrentDate = new Date();
        const timeRemaning = currrentDate.setSeconds(currrentDate.getSeconds() + (changedTime/1000));
        cookies.set(`${props.storeId}_mcp_freezeTime`, timeRemaning, { path: '/' });
        setIsModalOpen(false);
        setApiCalled(false);
      } else {
       setApiCalled(false);
       const cookies = new Cookies();
       cookies.remove(`${props.storeId}_mcp_delete_variant_clicked`, { path: '/' });
       cookies.remove(`${props.storeId}_mcp_delete_variant_timer`, { path: '/' });
      }
    } else {
      setApiCalled(false);
    }
  }

  const closeFreezeAdminPanel = () => {
    setOpenFrozeAdminPanel(false);
    const cookies = new Cookies();
    cookies.remove(`${props.storeId}_mcp_delete_variant_clicked`, { path: '/' });
    cookies.remove(`${props.storeId}_mcp_delete_variant_timer`, { path: '/' });
  }

  const closeConfirmationModal = () => {
    const cookies = new Cookies();
    const timer = cookies.get(`${props.storeId}_mcp_delete_variant_timer`);
    setTimer(timer);
    if(timer > 2000) {
      setTimeout(() => {
        setConfirmationModalOpen(false);
        setOpenFrozeAdminPanel(true);
      }, 1000);
    } else {
      setConfirmationModalOpen(false);
    }
  }

  const errorToastMarkup = isShowErrorToast  ?
  <Toast content="Something went wrong" onDismiss={setIsShowErrorToast(false)} /> : null; 

  const isEnterCodeValid = confirmVal === randomCode;

  const isRemoveProcessDone = () => {
    const cookies = new Cookies();
    const mcpFreezeTime = cookies.get(`${props.storeId}_mcp_freezeTime`);
    if(mcpFreezeTime) {
      if(new Date() < new Date(parseInt(mcpFreezeTime))) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  return (
    <>
      <div className="row deleteApp">
        <div className="cl-3">
          <TextContainer>
            <Heading element="h5"> <span className="red-color">Delete & Uninstalling </span></Heading>
            <p className="red-color">Danger zone</p>
          </TextContainer>
        </div>
        <div className="cl-9">
          <Card sectioned>
            <TextContainer>
              {/* <Heading element="h3">Current App status: Live & Working</Heading> */}
              <p>If you want to uninstall the app, please follow the three steps given below.</p>
            </TextContainer>
            <div className={`${Styles['step1']} mt-20`}>
              <div className={`${Styles['step']}`}><span className="bold-font">Step 1:</span> Click on this button to remove the basic setup done created by our app</div>
              <div className={`${Styles['button']}`}><Button disabled={props.isFroze} monochrome outline onClick={() => openModal()} >{props.isFroze ? (isRemoveProcessDone() ? "Done" : "In process") : "Remove basic setup"}</Button></div>
            </div>
            <div className={`mt-20`}>
              <div><span className="bold-font">Step 2:</span> If you want our team to remove the app's code from your store then please do the following two steps</div>
              <div className="mt-10"><List type="number">
                <List.Item>Make sure we have access to themes in your store. Follow <Link onClick={() => { openStaffAccessModal() }}>these steps</Link> to give us limited staff access. If you cannot give staff access then please mention the same when you are sending email in the point 2 below and we will send a collaborator request.</List.Item>
                <List.Item>Send an email to <span className="emailLink">support@mlveda.com</span> informing us to remove the app code from your store. Don't forget to mention your Shopify admin store URL in the email.</List.Item>
              </List></div>
            </div>
            <div className={`mt-20`}>
              <div><span className="bold-font">Step 3:</span>  After receiving your email, we will remove the app's code within the next two to three business days. We will send an email once we remove the app's code completely from your store. <span className="bold-font">After that you can simply delete the app from your Apps section in your Shopify admin.</span> </div>
              <div className="mt-10">
                <Banner status="info">
                  <p><span className="bold-font">Important Note:</span> Please do not remove the app without receiving the confirmation email from us regarding the app code removal.</p>
                </Banner>
              </div>
            </div>

          </Card>
        </div>
        {errorToastMarkup}
        {openFrozeAdminPanel && <FrozeAdminPanel timeInMs={timer} closeFreezeAdminPanel={closeFreezeAdminPanel} storeId={props.storeId} />}
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => { setIsModalOpen(false) }}
        title="Delete Confirmation"
        // primaryAction={{
        //   content: "Delete Forever",
        //   onAction: () => deleteVariants(),
        //   disabled: (apiCalled || !isEnterCodeValid),
        //   loading: apiCalled
        // }}
        // secondaryActions={[{
        //   content: "Cancel",
        //   onAction: () => { setIsModalOpen(false) },
          
        // }]}
      >
        <Modal.Section>
          <TextContainer>
            <div className={`${Styles['text-container']} d-flex flex-column align-items-center deleteConfirmation`}>
              <Icon
                color="red"
              
                source={RiskMinor} />
              <p>
                Once you delete your account,all of your information will be lost forever. We will not be able to restore
                your account. Are you sure want to proceed?
              </p>
              <div className="mt-15">
                <Heading element="h3">Confirm by typing {randomCode} below.</Heading>
              </div>
              <div className="mt-10" style={{ width: '150px' }}>
                <TextField
                  autoComplete="off"
                  value={confirmVal}
                  onChange={(val) => { setConfirmVal(val) }}
                />
              </div>
              <div className="mt-20">
                <ButtonGroup>
                  <Button
                    outline
                    onClick={() => { setIsModalOpen(false) }} >
                    Cancel
                  </Button>
                  <Button
                    primary
                    onClick={() => { deleteVariants() }}
                    loading={apiCalled} 
                    disabled={(apiCalled || !isEnterCodeValid)}
                  >
                    Delete Forever
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </TextContainer>
          {/* <div className="mt-20">
            <Banner status="info">
              <p>If you don't want the app to work on some theme then just <Link>disable the app from that particular theme.</Link></p>
            </Banner>
          </div> */}
        </Modal.Section>
      </Modal>

      <Modal
        open={staffAccessModalOpen}
        onClose={closeStaffAccessModal}
        title={`Step ${currentStepNumber} of 3`}
        primaryAction={{
          content: currentStepNumber === 3 ? "Done" : "Next",
          onAction: () => currentStepNumber === 3 ? closeStaffAccessModal() : handleStepChange('increase'),
        }}
        secondaryActions={currentStepNumber > 1 ? [
          {
            content: 'Cancel',
            onAction: () => closeStaffAccessModal(),
          },
          {
            content: 'Previous',
            onAction: () => handleStepChange('decrease'),
          }
        ] : [
          {
            content: 'Cancel',
            onAction: () => closeStaffAccessModal(),
          }
        ]}
      >
        <Modal.Section>
          <TextContainer>
          {currentStepNumber === 1 && <div>
            <div>Click on <span className="underline blue-color cursor-pointer" onClick={() => window.open(`https://${props.auth.storeDetails.myshopify_domain}/admin/settings/account/new`, "_blank")}>this link</span> (It will open in a new tab)</div>
            </div>}
            {currentStepNumber === 2 && <div>
              <div>Copy the below details and paste in the respective fields of Add staff section</div>
                <ul>
                    <li>First name = Multi Country Pricing</li>
                    <li>Second name = App</li>
                    <li>Email = shopify@mlveda.com</li>
                </ul>
                <img className={Styles["staffAccesImg"]} src={staffInfo} alt="Staff Info" />
            </div>}
            {currentStepNumber === 3 && <div>
              <div>In the permission section, select Orders, Products, Apps and channels, Themes and Manage settings and click on <span className="bold-font">Send invite</span> button below.</div>
              <img className={`mt-15 ${Styles["staffAccesImg"]}`} src={staffPermission} alt="Staff Permission" />
            </div>}
          </TextContainer>
        </Modal.Section>
      </Modal>

      <Modal
        open={confirmationModalOpen}
        title="Confirmation Message"
        primaryAction={{
          content: "Close",
          onAction: () => closeConfirmationModal()
        }}
      >
        <Modal.Section>
          <TextContainer>
          We have received your request and we will remove all the variants. 
          You will get an email from our backend team after that.
          </TextContainer>
          <div className="mt-20">
            <Banner status="info">
              <p><span className="bold-font">Important Note:</span> Please do not remove the app without receiving the confirmation from our side</p>
            </Banner>
          </div>
        </Modal.Section>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isFroze: state.settings.isFroze,
    error: state.settings.error,
    storeId: state.auth && state.auth.storeDetails && state.auth.storeDetails.myshopify_domain,
  }
}
const mapDispatchToProps = {
  updateSettings
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteApp);
