

import React, { useState, useCallback, useEffect } from 'react';
import { default as OnboardingLayout } from '../../../components/onboarding/Layout';
import Title from '../../../components/onboarding/title';
import { connect } from "react-redux";
import { Card, TextContainer, Button, Select, RadioButton, Stack, Autocomplete, Tag, Frame, Toast, Labelled, TextStyle } from '@shopify/polaris';
import Divider from '../../../components/onboarding/Divider/index';
import { useHistory } from 'react-router-dom';
import { updateOnboarding } from '../../../redux/actions/onboardingActions';
import { OnboardingStatus } from '../../../constants/common';
import MCPFooterHelp from '../../../components/common/MCPFooterHelp';

const REST_OF_THE_WORLD = 'Rest of The World';

const CreateMarketContainer = (props) => {
  const { updateOnboarding, defaultCountryOptions, onboarding, error } = props

  const [defaultStoreMarket, setDefaultStoreMarket] = useState('')
  const [storeMarkets, setStoreMarkets] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [optionList, setOptionList] = useState(defaultCountryOptions);
  const [priceSetOption, setPriceSetOption] = useState('PRICE_FOR_REST_OF_WORLD');
  const [isShowErrorToast, setIsShowErrorToast] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [marketSizeExceedError, setMarketSizeExceedError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if(error && error.error) {
      if(error.error !== "Invalid Session") {
        setIsShowErrorToast(true);
      }
    }
  }, [error]);

  useEffect(() => {
    // Set the all the value if onboarding detail exist
    if (onboarding) {
      setDefaultStoreMarket(onboarding?.defaultStoreMarket || '');
      if(onboarding && onboarding.storeMarkets) {
        if (onboarding?.storeMarkets && onboarding?.storeMarkets?.length > 0 && !onboarding?.storeMarkets?.includes(REST_OF_THE_WORLD)) {
          setPriceSetOption('SAME_PRICE_WORLDWIDE')
        }
        setStoreMarkets((oldVal) => ([...onboarding?.storeMarkets.filter((country) => country !== REST_OF_THE_WORLD)]))
      }
      if(onboarding && onboarding.defaultStoreMarket && onboarding.defaultStoreMarket.trim().length > 0) {
        const newDefaultCountryOptions = Object.assign([], defaultCountryOptions);
        const defaultCountryIndex = defaultCountryOptions.findIndex(i => i.value === onboarding.defaultStoreMarket);
        if(defaultCountryIndex > -1) {
          newDefaultCountryOptions.splice(defaultCountryIndex, 1);
          setOptionList(newDefaultCountryOptions);
        }
      }
    }
  }, [onboarding])

  const updateText = (value) => {
      setInputValue(value);
      let newDefaultCountryOptions = Object.assign([], defaultCountryOptions);
      if(defaultStoreMarket && defaultStoreMarket.trim().length > 0) {
        const defaultCountryIndex = defaultCountryOptions.findIndex(i => i.value === defaultStoreMarket);
        if(defaultCountryIndex > -1) {
          newDefaultCountryOptions.splice(defaultCountryIndex, 1);
        }
      }

      if (value === '') {
        setOptionList(newDefaultCountryOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = newDefaultCountryOptions.filter((option) =>
        option.label.match(filterRegex),
      );

      setOptionList(resultOptions);
    };

  const textField = (
    <Autocomplete.TextField
      disabled={apiCalled}
      onChange={updateText}
      value={inputValue}
      placeholder="Select your international markets"
      error={marketSizeExceedError ? "Please select maximum 5 international markets for now." : ""}
    />
  );

  const handleSelectChange = (value) => {
      setDefaultStoreMarket(value);
      const newDefaultCountryOptions = Object.assign([], optionList);
      const defaultCountryIndex = newDefaultCountryOptions.findIndex(i => i.value === value);
      if(defaultCountryIndex > -1) {
        newDefaultCountryOptions.splice(defaultCountryIndex, 1);
        setOptionList(newDefaultCountryOptions);
      }

      const options = [...storeMarkets];
      const optionIndex = options.findIndex(i => i === value);
      if(optionIndex > -1) {
        options.splice(optionIndex, 1);
        setStoreMarkets(options);
        if(options.length > 5) {
          setMarketSizeExceedError(true);
        } else {
          setMarketSizeExceedError(false);
        }
      }
    };

  const handleChange = (_checked, val) => {
    setPriceSetOption(val)
  }

  const removeTag = useCallback(
    (tag) => () => {
      const options = [...storeMarkets];
      options.splice(options.indexOf(tag), 1);
      setStoreMarkets(options);
      if(marketSizeExceedError && options.length <= 5) {
        setMarketSizeExceedError(false);
      }
    },
    [storeMarkets],
  );

  const tagsMarkup = storeMarkets.map((option) => {
    return (
      <Tag key={`option${option}`} onRemove={removeTag(option)}>
        {option}
      </Tag>
    );
  });

  /**
   * Create object and call the put api to update onboarding details
   */
  const handleStateChange = async () => {
    // If `Rest of the world` option then remove for same price worldwide
    // else push 'Rest of the world` option to the store markets
    setApiCalled(true);

    // if(storeMarkets.length > 5) {
    //   setMarketSizeExceedError(true);
    //   setApiCalled(false);
    //   return;
    // }

    const foundIndex = storeMarkets.findIndex((sm) => sm === REST_OF_THE_WORLD)
    let tmpStoreMarkets = [...storeMarkets]
    if (priceSetOption === 'SAME_PRICE_WORLDWIDE') {
      if (foundIndex > -1) {
        tmpStoreMarkets.splice(foundIndex, 1)
      }
    }
    else {
      if (foundIndex <= -1) {
        tmpStoreMarkets.push(REST_OF_THE_WORLD)
      }
    }
    // Prepare the object
    const toBeUpdatedData = {
      currentStepNumber: 2,
      status: OnboardingStatus.ONBOARDING_STARTED,
      defaultStoreMarket,
      storeMarkets: tmpStoreMarkets
    }
    // Dispatch the action to update the onboarding detail
    await updateOnboarding(toBeUpdatedData);
    setApiCalled(false);
  }

  const redirectToStep0 = () => {
    const toBeUpdatedData = {
      currentStepNumber: 0,
      status: OnboardingStatus.ONBOARDING_STARTED,
    }
    // Dispatch the action to update the onboarding detail
    updateOnboarding(toBeUpdatedData)
  }

  const handleMultiSelect = (e) => {
    if(!marketSizeExceedError && e.length > 5) {
      setMarketSizeExceedError(true);
    }
    setStoreMarkets(e);
  }

  return (
    <OnboardingLayout>

      {/* Show current step of user */}
      {/* <StepIndicator
        totalSteps={2}
        curStep={1}
      /> */}

      {/* Show the onboarding title */}
      <Labelled 
        label={<Title displayText="Step 1: Select your primary market"/>}
        helpText="Select a country/continent where you want to keep selling at the prices that are already there in your Shopify store. From now on, we call it as your primary market.">
      </Labelled>

      <Divider />

      {/* Show onboarding Body card */}
      <Card sectioned>
        <Labelled
          label={
            <>
              <div className='Polaris-Heading bold-font'>Primary market</div>
              {/* <TextStyle variation='subdued'>
                  <span className='mediumFontSized plainFonts'>Select a country/continent where you want to keep selling at the prices that are already there in your Shopify store. From now on, we call it as your <span className="underline">primary market</span>.</span>
              </TextStyle> */}
            </>
          }>
          </Labelled>
          <div className="row">
            <div className="cl-md-10 cl-12">

              <Select
                options={defaultCountryOptions}
                onChange={handleSelectChange}
                value={defaultStoreMarket}
                disabled={apiCalled}
              />
            </div>
          </div>

        {/* <div className="mt-40">
          <Labelled
            label={
              <>
                <div className='Polaris-Heading bold-font'>Select your international markets (Max 5 for now)</div>
                <TextStyle variation='subdued'>
                    <span className='mediumFontSized plainFonts'>Select maximum 5 countries or continents where you want to sell your products at different prices. You can add more markets later.</span>
                  </TextStyle>
              </>
            }>
            </Labelled>
            <div className="row">
              <div className="cl-md-10 cl-12">
                <TextContainer>
                  <Stack>{tagsMarkup}</Stack>
                </TextContainer>
                <br />
                <Autocomplete
                  allowMultiple
                  options={optionList}
                  selected={storeMarkets}
                  textField={textField}
                  onSelect={(e) => {handleMultiSelect(e)}}
                  listTitle="Suggested countries"
                />
              </div>
            </div>
        </div> */}

        {/* <div className="mt-40">
          <Labelled
            label={
              <>
                <div className='Polaris-Heading bold-font'>Select the prices for the remaining markets</div>
                <TextStyle variation='subdued'>
                    <span className='mediumFontSized plainFonts'>Decide at what price you want to sell for the countries other than your primary and international markets</span>
                </TextStyle>
              </>
            }>
            </Labelled>
            <RadioButton
              disabled={apiCalled}
              label="I want to keep selling at the current Shopify prices"
              checked={priceSetOption === 'SAME_PRICE_WORLDWIDE'}
              id="SAME_PRICE_WORLDWIDE"
              name="priceOption"
              onChange={handleChange}
            />
            <RadioButton
              disabled={apiCalled}
              label="I want to set different prices"
              id="PRICE_FOR_REST_OF_WORLD"
              name="priceOption"
              checked={priceSetOption === 'PRICE_FOR_REST_OF_WORLD'}
              onChange={handleChange}
            />
        </div> */}

      </Card>
      
      <div className="onboarding-footer-btn-main createMarketOnboarding">
        <Stack distribution='fillEvenly'>
          {/* <Button onClick={redirectToStep0} fullWidth>Back</Button> */}
          <Button
              fullWidth
              loading={apiCalled}
              disabled={apiCalled}
              primary
              onClick={handleStateChange}
            >Next</Button>
        </Stack>
      </div>

      <MCPFooterHelp />

      {/* <OnboardingFooter
        leftBtn={() => <Button onClick={redirectToStep0}>Back</Button>}
        rightBtn={() => (<Button
          loading={apiCalled}
          disabled={apiCalled}
          primary
          onClick={handleStateChange}
        >
          Go to step 2
        </Button>)} /> */}

        {isShowErrorToast && <Frame>
            <Toast
                error
                duration={1500}
                content={"Something went wrong!"}
                onDismiss={() => { setIsShowErrorToast(false) }} />
        </Frame>}
    </OnboardingLayout>
  );
};

CreateMarketContainer.propTypes = {

};

const mapStateToProps = (state) => {
  const { globalMarketOptions, countryOptions, currencyOptions } = state.globalMarkets
  return {
    onboarding: state.onboarding.onboarding,
    globalMarketOptions,
    defaultCountryOptions: globalMarketOptions.filter(i => i.regionType !== 'worldwide').map((gm) => ({ value: gm.regionName, label: gm.regionName })),
    currencyOptions,
    error: state.onboarding.error
  }
}
const mapDispatchToProps = {
  updateOnboarding
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMarketContainer);
