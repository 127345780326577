import React from 'react';
import { Heading, Banner, TextContainer, Card, Link, Avatar, Button, ButtonGroup } from '@shopify/polaris';
import Styles from './styles.module.scss';
import CEO from '../../assets/images/CEO.png'

const RelationshipManager = () => {
  return (
    <>
      <div className="row">
        <div className="cl-3">
          <TextContainer>
            <Heading element="h5">Relationship Manager</Heading>
            <p>Your dedicated relationship manager details</p>
          </TextContainer>
        </div>
        <div className="cl-9">
          <Card sectioned>
            <div className={`${Styles['info-container']} d-flex mb-20`}>
              <div><Avatar customer name="Reshma Bhagwani" source={CEO} /></div>
              <div className={`${Styles['right-container']} ml-15`}>
                <span className="emailLink">Reshma Bhagwani</span>
                <p className="mb-5 mt-5">Please write to us at <span className="emailLink">support@mlveda.com</span> if you have any queries</p>
                <p className="mt-15">You can book a call with our team and we will guide you in a live call using screen share.</p>
                <div className={`d-flex mt-5`}>
                  <ButtonGroup>
                    {/* <Button>Request call back</Button> */}
                    <Button onClick={() => {window.open("https://calendly.com/mlveda-team/", "_blank")}}>Book a meeting</Button>
                  </ButtonGroup>

                </div>
                <p className="mt-15">We work in GMT+5.30 time zone. We usually respond within one to two business days.</p>
              </div>
            </div>

          </Card>
        </div>
      </div>
    </>
  );
};



export default RelationshipManager;