import { GET_EMBEDED_BLOCK_STATUS_BEGIN, GET_EMBEDED_BLOCK_STATUS_SUCCESS, GET_EMBEDED_BLOCK_STATUS_FAILURE } from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  status: null,
}

const embededBlockStatus = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMBEDED_BLOCK_STATUS_BEGIN:
      return { ...state, isLoading: true, error: {} };
    case GET_EMBEDED_BLOCK_STATUS_SUCCESS:
      return { ...state, ...action.payload.embededBlockStatus, isLoading: false, error: {} };
    case GET_EMBEDED_BLOCK_STATUS_FAILURE:
      return { ...state, error: action.payload.error, isLoading: false };
    default:
      return state;
  }
}

export default embededBlockStatus;