import React from "react";
import {
  Heading,
  TextContainer,
  Button,
} from "@shopify/polaris";
import Styles from "./styles.module.scss";
import InvoiceHero from "../../assets/images/invoiceHero.png";
import CartPencil from "../../assets/images/CartPencil.webp";

const OtherMLVedaApps = () => {
  return (
    <>
      <div className="row">
        <div className="cl-3">
          <TextContainer>
            <Heading element="h5">MLVeda Popular Apps</Heading>
            <p>Other Popular apps by MLVeda</p>
          </TextContainer>
        </div>
        <div className="cl-9">
        <div className={`${Styles['partners-app-card']}`}>

<div className={Styles["img-section"]}>
  <img src={InvoiceHero} alt="Invoice Hero PDF" />
</div>
<div className={Styles["description-section"]}>
  <div className={Styles["app-name"]}>
    Invoice Hero PDF
    {/* <span className="price-off">40% off</span> */}
  </div>
  <div className={Styles["app-desc"]}>
    Send PDF invoices to your customers automatically. Generate
    packaging slips and print orders.
  </div>
</div>
<div className={Styles["button-section"]}>
    <Button primary onClick={() => { window.open("https://apps.shopify.com/invoice-hero?utm_source=mcp-app-providers&utm_medium=mlveda&utm_campaign=app-listing&utm_content=in-app", "_blank") }}>Explore</Button>
</div>

</div>

<div className={`${Styles['partners-app-card']} mt-20`}>
<div className={Styles["img-section"]}>
  <img src={CartPencil} alt="Cart Pencil" />
</div>
<div className={Styles["description-section"]}>
  <div className={Styles["app-name"]}>
  Cart Pencil
    {/* <span className="price-off">40% off</span> */}
  </div>
  <div className={Styles["app-desc"]}>
  Add edit functionality to your cart page. Edit or change product variant directly on your cart page.
  </div>
</div>
<div className={Styles["button-section"]}>
    <Button primary onClick={() => { window.open("https://apps.shopify.com/edit-cart?utm_source=mcp-app-providers&utm_medium=mlveda&utm_campaign=app-listing&utm_content=in-app", "_blank") }}>Explore</Button>
</div>

</div>
        </div>
        
      </div>
    </>
  );
};

export default OtherMLVedaApps;
