import { GET_STORE_DETAILS_BEGIN, GET_STORE_DETAILS_SUCCESS, GET_STORE_DETAILS_FAILURE } from './actionTypes';
import authApi from "../../services/authApi"

export const getStoreDetailsBegin = (storeDetails) => {
  return { type: GET_STORE_DETAILS_BEGIN }
}
export const getStoreDetailsSuccess = (storeDetails) => {
  return { type: GET_STORE_DETAILS_SUCCESS, payload: { storeDetails } }
}
export const getStoreDetailsFailure = (error) => {
  return { type: GET_STORE_DETAILS_FAILURE, payload: { error } }
}

export const getStoreDetails = () => (dispatch) => {

  dispatch(getStoreDetailsBegin());
  return authApi.getStoreDetails()
    .then((response) => {
      dispatch(getStoreDetailsSuccess(response.data));
      return response.data;
    }).catch((error) => {
      dispatch(getStoreDetailsFailure(error));
      return Promise.reject({ error: error });
    })

}