import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import InventoryManagement from '../../components/InventoryManagement/index';
import { ContextualSaveBar, Stack, Image, Frame, Loading, Toast, Modal, TextContainer, Link } from "@shopify/polaris";
import { updateSettings } from '../../redux/actions/settingsActions';
import { updateOnboarding } from '../../redux/actions/onboardingActions';
import SectionDivider from '../../components/SectionDivider/index';
import ThemeLibrary from '../../components/ThemeLibrary';
import RoundingRules from '../../components/RoundingRulesContainer';
import logo from '../../assets/images/logo.png';
import { connect } from 'react-redux';
import roundingApi from '../../services/roundingApi';
import productApi from '../../services/productsApi';
import FrozeAdminPanel from '../../containers/FrozeAdminPanel';
import MCPFooterHelp from '../../components/common/MCPFooterHelp';
import BulkDownload from '../../components/BulkDownload';
import bulkActionApi from "../../services/bulkActionApi";
import roundingDisabled from '../../assets/images/roundingDisabled.png';
import CommunicationDetails from '../../components/CommunicationDetails';
import RemoveVariants from '../../components/RemoveVariants';

const SettingContainer = (props) => {
  const { updateSettings, error, updateOnboarding, onboarding } = props;
  const [inventoryOption, setInventoryOption] = useState(null);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [toast, setToast] = useState(false);
  const [isShowErrorToast, setIsShowErrorToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [roundings, setRoundings] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [currentEditRule, setCurrentEditRule] = useState(null);
  const [editModalOpen, setOpenEditModal] = useState(false);
  const [roundingApiCalled, setRoundingApiCalled] = useState(false);
  const [roundingApiError, setRoundingApiError] = useState('');
  const [openFrozeAdminPanel, setOpenFrozeAdminPanel] = useState(false);
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false);
  const [timer, setTimer] = useState(0);
  const [openRoundingDisabledModal, setOpenRoundingDisabledModal] = useState(false);
  const [bulkDownloadApiCalled, setBulkDownloadApiCalled] = useState(false);
  const [bulkDownloadApiError, setBulkDownloadApiError] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [communicationEmailId, setCommunicationEmailId] = useState('');
  const [communicationName, setCommunicationName] = useState('');
  const [requiredEmailError, setRequiredEmailError] = useState('');
  const [requiredNameError, setRequiredNameError] = useState('');
  const [verifyRoundingApiCalled, setVerifyRoundingApiCalled] = useState(false);
  const [verifyRoundingError, setVerifyRoundingError] = useState('');
  const [roundingDisabledToast, setRoundingDisabledToast] = useState(false);
  const [isRemoveVariantConfirmationModalOpened, setIsRemoveVariantConfirmationModalOpened] = useState(false);
  const [removeVariantApiCalled, setRemoveVariantApiCalled] = useState(false);

  useEffect(() => {
    if(error && error.error) {
      if(error.error !== "Invalid Session") {
        setIsShowErrorToast(true);
      }
    }
  }, [error]);

  useEffect(() => {
    if(onboarding.communicationEmailId || onboarding.communicationName) {
      setCommunicationEmailId(onboarding.communicationEmailId ? onboarding.communicationEmailId : '');
      setCommunicationName(onboarding.communicationName ? onboarding.communicationName : '');
    }
  }, [onboarding]);

  const handleChange = useCallback((newValue, key) => {
    if(key === "communicationEmailId") {
      setRequiredEmailError(false);
      setEmailError(false);
      setCommunicationEmailId(newValue);
    } else {
      setRequiredNameError(false);
      setCommunicationName(newValue);
    }
  }, []);

  const openModal = (rule) => {
    setCurrentEditRule(rule);
    setOpenEditModal(true);
  }

  const closeModal = () => {
    setCurrentEditRule(null);
    setOpenEditModal(false);
  }

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpened(false);
  }

  const closeRemoveVariantConfirmationModal = () => {
    setIsRemoveVariantConfirmationModalOpened(false);
  }

  const handleSelectChange = (value) => {
    const updatedRule = {
      ...currentEditRule,
      selected: parseFloat(value)
    }
    setCurrentEditRule(updatedRule);
  };

  const toggleToast = useCallback(() => setToast((toast) => !toast), []);

  const toggleRoundingToast = useCallback(() => setRoundingDisabledToast((toast) => !toast), []);

  useEffect(() => {
    setIsLoading(true);
    roundingApi.getStoreRoundings()
      .then(storeRoundings => {
        if(storeRoundings.data && storeRoundings.data.rounding_rules && storeRoundings.data.rounding_rules.length > 0) {
          storeRoundings = formatStoreRoundings(storeRoundings);
        }

        // storeRoundings.data = Object.assign({}, storeRoundings.data, {rounding_rules_enabled: false});
        if(storeRoundings.data && storeRoundings.data.rounding_rules_enabled !== undefined && !storeRoundings.data.rounding_rules_enabled) {
          setOpenRoundingDisabledModal(true);
        }

        setRoundings(storeRoundings.data && storeRoundings.data.rounding_rules ? storeRoundings.data.rounding_rules : []);
      })
      .catch(err => {
        setIsShowErrorToast(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    checkFormChanged();
  }, [inventoryOption, communicationName, communicationEmailId]);

  useEffect(() => {
    setInventoryOption(props.stockSync);
  }, [props.stockSync]);

  const handleInventoryChange = (_checked, selectedOption) => {
    setInventoryOption(selectedOption)
  }

  const checkFormChanged = () => {
    if (inventoryOption !== props.stockSync || 
      communicationEmailId !== onboarding.communicationEmailId || 
      communicationName !== onboarding.communicationName) {
      setIsFormChanged(true);
    } else {
      setIsFormChanged(false)
    }
  };

  const saveSettings = async () => {
    if(communicationEmailId !== onboarding.communicationEmailId || communicationName !== onboarding.communicationName) {
      if(communicationEmailId.trim().length <= 0) {
        setRequiredEmailError(true);
        return;
      }
      if(communicationName.trim().length <= 0) {
        setRequiredNameError(true);
        return;
      }
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(communicationEmailId)) {
        setEmailError(true);
        return;
      }
    }
    if (inventoryOption !== props.stockSync) {
      await updateSettings({stockSync: inventoryOption});
    }
    if(communicationEmailId !== onboarding.communicationEmailId || communicationName !== onboarding.communicationName) {
      await updateOnboarding({communicationEmailId: communicationEmailId, communicationName: communicationName});
    }
    setToast(true);
    setIsFormChanged(false);
  }

  const setDefaultState = () => {
    setInventoryOption(props.stockSync);
  }

  const openConfirmationModal = () => {
    productApi.getProductCount().then(countData => {
      const count = countData.data.count || 0;
      setTimer(count * 2 * 1000);
      setOpenEditModal(false);
      setIsConfirmationModalOpened(true);
    }).catch(error => {
      setIsShowErrorToast(true);
    })
  }

  const openRemoveVariantsConfirmationModal = () => {
    productApi.getProductCount().then(countData => {
      const count = countData.data.count || 0;
      setTimer(count * 23 * 1000);
      setIsRemoveVariantConfirmationModalOpened(true);
    }).catch(error => {
      setIsShowErrorToast(true);
    })
  }

  const saveRoundingRule = (currentEditRule) => {
    setRoundingApiCalled(true);
    const putData = {
      rounding_rules: [
        {currency: currentEditRule.currency, rule: currentEditRule.selected}
      ]
    };
    roundingApi.updateStoreRoundings(putData).then((roundingRules) => {
      // const updatedRules = [];
      for(let rule of roundingRules.data.rounding_rules) {
        const updatedRule = roundings.find(i => i.currency === rule.currency);
        if(updatedRule) {
          updatedRule.selected = rule.rule;
          updatedRule.textFieldSelected = rule.reference_rule === rule.rule ? rule.rule + " (recommended)" : rule.rule.toString();
          // updatedRules.push(updatedRule);
        }
      }
      setRoundings(roundings);
      setRoundingApiCalled(false);
      setToast(true);
      closeConfirmationModal();
      if(timer > 2000) {
        setOpenFrozeAdminPanel(true);
      }
    }).catch((error) => {
      setRoundingApiCalled(false);
      setRoundingApiError(error);
    })
  }

  const removeVariants = async () => {
    setIsRemoveVariantConfirmationModalOpened(false);
    setRemoveVariantApiCalled(true);
    const variants = await productApi.removeVariants().catch(err => {
      setIsShowErrorToast(true);
    });
    if(variants) {
      if(timer > 2000) {
        setRemoveVariantApiCalled(false);
        setOpenFrozeAdminPanel(true);
      }
    } else {
      setRemoveVariantApiCalled(false);
    }
  }

  const downloadCSV = () => {
    setBulkDownloadApiCalled(true);
    bulkActionApi.downloadCSV().then(res => {
      if(res && res.data && res.data.links) {
        setBulkDownloadApiCalled(false);
        window.open(res.data.links, "_blank");
      } else {
        setBulkDownloadApiCalled(false);
      }
    }).catch(err => {
      setBulkDownloadApiCalled(false);
      setBulkDownloadApiError(true);
    })
  }

  const closeFreezeAdminPanel = () => {
    setOpenFrozeAdminPanel(false);
  }

  const toastMarkup = toast ? (
    <Toast content="Saved successfully." onDismiss={toggleToast} />
  ) : null;

  const roundingDisabledToastMarkup = roundingDisabledToast ? (
    <Toast content="Rounding is disabled." onDismiss={toggleRoundingToast} />
  ) : null;

  const errorToastMarkup = (isShowErrorToast || roundingApiError || bulkDownloadApiError || verifyRoundingError) ?
  <Toast content="Something went wrong" onDismiss={setIsShowErrorToast(false)} /> : null; 

  const formatStoreRoundings = (storeRoundings) => {
    for(let rule of storeRoundings.data.rounding_rules) {
      rule.selected = rule.rule;
      rule.textFieldSelected = rule.reference_rule === rule.rule ? rule.rule + " (recommended)" : rule.rule.toString();
      rule.selectOption = [];
      for(let option of rule.possible_rules) {
        rule.selectOption.push({label: option.rule + (option.isDefault ? " (recommended)" : ''), value: option.rule})
      }
    }
    const primaryCurrenyRule = storeRoundings.data.rounding_rules.find(i => i.currency === props.storeCurrency);
    if(primaryCurrenyRule) {
      const index = storeRoundings.data.rounding_rules.indexOf(primaryCurrenyRule);
      if(index > -1) {
        storeRoundings.data.rounding_rules.splice(index, 1);
        storeRoundings.data.rounding_rules = [primaryCurrenyRule].concat(storeRoundings.data.rounding_rules);
      }
    }
    return storeRoundings;
  }

  const roundingVerify = () => {
    setVerifyRoundingApiCalled(true);
    roundingApi.verifyRounding().then(verfiyData => {
      if(verfiyData.data.rounding_rule_status) {
        window.location.reload();
      } else {
        setRoundingDisabledToast(true);
        setVerifyRoundingApiCalled(false);
      }
    })
    .catch(verfiyErr => {
      setVerifyRoundingApiCalled(false);
      setVerifyRoundingError(true);
    })
  }
 
  return (
    <div className="row">
      <div className="cl-10 my-0 mx-auto">
        {isLoading && <div style={{ height: '100px' }}>
          <Frame>
            <Loading />
          </Frame>
        </div>}
        {!isLoading && <div>
          {isFormChanged && <ContextualSaveBar
            contextControl={
              <Stack alignment='center' spacing='tight' distribution='leading'>
                <Stack.Item>
                  <Image
                    alt=''
                    source={logo}
                    className='app__contexualSaveBar__logo'
                  />
                </Stack.Item>
                <Stack.Item>
                  <span className='app__contexualSaveBar__titleText'>
                  Multi Country Pricing
            </span>
                </Stack.Item>
              </Stack>
            }
            message="Unsaved changes"
            saveAction={{
              onAction: () => saveSettings(),
              loading: props.isSettingsLoading ? true : false,
              disabled: props.isSettingsLoading ? true : false,
            }}
            discardAction={{
              onAction: () => setDefaultState(),
            }}
          />}
          <InventoryManagement
            inventoryOption={inventoryOption}
            onChange={handleInventoryChange} />
          {toastMarkup}
          {errorToastMarkup}
          {roundingDisabledToastMarkup}

          {roundings && roundings.length> 0 && <SectionDivider />}

          {roundings && roundings.length> 0 && <RoundingRules roundings={roundings} saveRoundingRule={saveRoundingRule}
            showAll={showAll} setShowAll={setShowAll}
            currentEditRule={currentEditRule} setCurrentEditRule={setCurrentEditRule}
            editModalOpen={editModalOpen} setOpenEditModal={setOpenEditModal}
            openModal={openModal} closeModal={closeModal}
            handleSelectChange={handleSelectChange}
            roundingApiCalled={roundingApiCalled}
            storeCurrency={props.storeCurrency}
            isConfirmationModalOpened={isConfirmationModalOpened} closeConfirmationModal={closeConfirmationModal}
            timer={timer} openConfirmationModal={openConfirmationModal} />}

          {openFrozeAdminPanel && <FrozeAdminPanel timeInMs={timer} closeFreezeAdminPanel={closeFreezeAdminPanel} storeId={props.storeId} />}

          <SectionDivider />

          <BulkDownload downloadCSV={downloadCSV} bulkDownloadApiCalled={bulkDownloadApiCalled} />

          <SectionDivider />

          <RemoveVariants openRemoveVariantsConfirmationModal={openRemoveVariantsConfirmationModal}
            isRemoveVariantConfirmationModalOpened={isRemoveVariantConfirmationModalOpened}
            timer={timer}
            closeRemoveVariantConfirmationModal={closeRemoveVariantConfirmationModal}
            removeVariants={removeVariants}
            removeVariantApiCalled={removeVariantApiCalled} />

          <SectionDivider />

          {/* <CommunicationDetails communicationName={communicationName}
            communicationEmailId={communicationEmailId}
            handleChange={handleChange}
            requiredNameError={requiredNameError}
            emailError={emailError}
            requiredEmailError={requiredEmailError} /> */}

          <MCPFooterHelp />

          <Modal
            title="Enable Price rounding in Shopify payments"
            open={openRoundingDisabledModal}
            primaryAction={{
              content: "Verify",
              loading: verifyRoundingApiCalled,
              disabled: verifyRoundingApiCalled,
              onAction: () => roundingVerify(),
            }}
          >
            <Modal.Section>
              <TextContainer>
              <p>
            Please enable <span className="bold-font">Price rounding</span> in your Shopify payments. Our app will not work unless you enable rounding.
            </p>
            <p className="mt-15">Open this <Link onClick={() => { window.open(`https://${props.storeId}/admin/settings/payments/shopify-payments`) }}>page</Link> and click Enable button in the <span className="bold-font">Price rounding</span> section as shown below. Your dashboard will be enabled within an hour after enabling it</p>
            <img className="roundingImg" src={roundingDisabled} alt="Rounding" />
            <p className="mt-15">Please write to us at <span className="emailLink">support@mlveda.com</span> if you have any queries</p>
              </TextContainer>
            </Modal.Section>
          </Modal>
        </div>}
        
        {/* <SectionDivider /> */}
        {/* <ThemeLibrary /> */}

      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    stockSync, isLoading, error
  } = state.settings;

  return {
    isSettingsLoading: isLoading,
    stockSync: stockSync,
    error: error,
    storeCurrency: state.auth && state.auth.storeDetails && state.auth.storeDetails.currency,
    storeId: state.auth && state.auth.storeDetails && state.auth.storeDetails.myshopify_domain,
    onboarding: state.onboarding.onboarding
  }
};

const mapDispatchToProps = {
  updateSettings,
  updateOnboarding
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingContainer);