import { GET_SETTINGS_BEGIN, GET_SETTINGS_SUCCESS, GET_SETTINGS_FAILURE, UPDATE_SETTINGS_BEGIN, UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_FAILURE } from '../actions/actionTypes';
import settingsApi from "../../services/settingsApi";

export const getSettingsBegin = () => {
  return { type: GET_SETTINGS_BEGIN }
}
export const getSettingsSuccess = (settings) => {
  return { type: GET_SETTINGS_SUCCESS, payload: { settings } }
}
export const getSettingsFailure = (error) => {
  return { type: GET_SETTINGS_FAILURE, payload: { error } }
}

export const updateSettingsBegin = () => {
  return { type: UPDATE_SETTINGS_BEGIN }
}
export const updateSettingsSuccess = (settings) => {
  return { type: UPDATE_SETTINGS_SUCCESS, payload: { settings } }
}
export const updateSettingsFailure = (error) => {
  return { type: UPDATE_SETTINGS_FAILURE, payload: { error } }
}

export const getSettings = () => (dispatch) => {
  dispatch(getSettingsBegin());

  return settingsApi.getSettings()
    .then((response) => {
      dispatch(getSettingsSuccess(response.data));
    }).catch((error) => {
      dispatch(getSettingsFailure(error));
    })
}

export const updateSettings = (settings) => (dispatch) => {
  dispatch(updateSettingsBegin());

  return settingsApi.updateSettings(settings)
    .then((response) => {
      dispatch(updateSettingsSuccess(response.data));
      return response.data;
    }).catch((error) => {
      dispatch(updateSettingsFailure(error));
      return Promise.reject({ error: error });
    })
}