import React from 'react';
import PropTypes from 'prop-types';
import Styles from './styles.module.scss';
import { Collapsible, TextContainer, Icon, TextStyle } from '@shopify/polaris';
import {
  ChevronRightMinor,
  ChevronDownMinor
} from '@shopify/polaris-icons';

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <div className={Styles['container']}>
      <div
        className={`${Styles['header']} d-flex justify-content-between`}
        onClick={() => { setIsOpen(!isOpen) }}
      >
        <div className={Styles['title']}>
          <TextStyle variation="strong">  {title}</TextStyle>
        </div>
        <div className={Styles['icon-container']}>
          {isOpen ? <Icon source={ChevronDownMinor} /> : <Icon source={ChevronRightMinor} />}

        </div>
      </div>

      <div className={`${Styles['body']} mt-5`}>
        <Collapsible
          open={isOpen}
          id="basic-collapsible"
          transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
          expandOnPrint
        >
          {children}
        </Collapsible>
      </div>

    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string
};

export default Accordion;