

import React, { useState, useCallback, useEffect } from 'react';
import { default as Layout1 } from '../../../components/onboarding/Layout';
import Title from '../../../components/onboarding/title';
import { connect } from "react-redux";
import { Card, Button, Toast } from '@shopify/polaris';
import Divider from '../../../components/onboarding/Divider';
import { updateOnboarding } from '../../../redux/actions/onboardingActions';
import { OnboardingStatus } from '../../../constants/common';
import MCPFooterHelp from '../../../components/common/MCPFooterHelp';
import Style from './styles.module.scss';


const SetupInstruction = ({ updateOnboarding, ...props }) => {
  const { error } = props;
  const [toast, setToast] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);

  useEffect(() => {
    if(error && error.error) {
      if(error.error !== "Invalid Session") {
        setToast(true);
      }
    }
  }, [error])

  // Based on user's choice update the onboarding details and take to step1 or else to help page
  const handleClick = () => {
    setApiCalled(true);
    const toBeUpdatedData = {
      currentStepNumber: 1,
      status: OnboardingStatus.ONBOARDING_STARTED,
    }
    // Dispatch the action to update the onboarding detail
    updateOnboarding(toBeUpdatedData);
    setApiCalled(false);
  }

  const toggleActive = useCallback(() => setToast((toast) => !toast), []);

  const toastMarkup = toast ? (
    <Toast content="Something went wrong!" duration={1500} error onDismiss={toggleActive} />
  ) : null;

  return (
    <Layout1>
        
      {/* Show the onboarding title */}
      <Title
        displayText="App Setup"
      />

      <Divider />
      {/* Show onboarding Body card */}
      <Card sectioned>
        <div>This app setup involves two parts </div>
        <div className="mt-20">
          <p><span className={Style["heading"]}>First,</span> you will select the necessary fields from the app's admin.<span className={Style["heading"]}> Next,</span> you will need to do some changes within Shopify's admin to get the app up and running on the store.</p>
        </div>
        <div className="welcome-footer-btn-main">
          <Button primary onClick={handleClick} disabled={apiCalled} loading={apiCalled}>Start Setup</Button>
        </div>
      </Card>

      <MCPFooterHelp />

      {/* <OnboardingFooter
        leftBtn={() => { }}
        rightBtn={() => (<Button
          primary
          onClick={handleClick}
        >
          Install
        </Button>)} /> */}


          {toastMarkup}

    </Layout1>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.onboarding.error
  }
}
const mapDispatchToProps = {
  updateOnboarding
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupInstruction);
