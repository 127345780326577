import httpInterceptor from '../helpers/httpInterceptor';
const PRODUCT_END_POINT = process.env.REACT_APP_PRODUCT_API_ENDPOINT;

export default {
  getAllRoundings(params) {
    return httpInterceptor.get(PRODUCT_END_POINT + 'all_rounding_rules', {
      params
    })
  },
  getStoreRoundings(params) {
    return httpInterceptor.get(PRODUCT_END_POINT + 'rounding_rules', {
      params
    })
  },
  updateStoreRoundings(putData) {
    return httpInterceptor.put(PRODUCT_END_POINT + 'rounding_rules', putData)
  },
  verifyRounding(params) {
    return httpInterceptor.get(PRODUCT_END_POINT + 'rounding_rule_status', {
      params
    })
  }
}