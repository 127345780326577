import { GET_STORE_DETAILS_SUCCESS, GET_STORE_DETAILS_BEGIN, GET_STORE_DETAILS_FAILURE } from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  storeDetails: {},
  isAuthenticated: false,
  error: {}
}
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_DETAILS_BEGIN:
      return { ...state, isLoading: true, error: {} };
    case GET_STORE_DETAILS_SUCCESS:
      return { ...state, storeDetails: action.payload.storeDetails, isAuthenticated: true, isLoading: false, error: {} };
    case GET_STORE_DETAILS_FAILURE:
      return { ...state, error: action.payload.error, isAuthenticated: false, isLoading: false };
    default:
      return state;
  }
}

export default authReducer;