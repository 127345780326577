import { GET_SETTINGS_BEGIN, GET_SETTINGS_SUCCESS, GET_SETTINGS_FAILURE, UPDATE_SETTINGS_BEGIN, UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_FAILURE } from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  stockSync: null,
  isFroze: null,
  enabledShopifyMultiCurrency: null,
  multiCountryCurrency: null
}

const settings = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS_BEGIN:
      return { ...state, isLoading: true, error: {} };
    case GET_SETTINGS_SUCCESS:
      return { ...state, ...action.payload.settings, isLoading: false, error: {} };
    case GET_SETTINGS_FAILURE:
      return { ...state, error: action.payload.error, isLoading: false };
    case UPDATE_SETTINGS_BEGIN:
      return { ...state, isLoading: true, error: {} };
    case UPDATE_SETTINGS_SUCCESS:
      return { ...state, ...action.payload.settings, isLoading: false, error: {} };
    case UPDATE_SETTINGS_FAILURE:
      return { ...state, error: action.payload.error, isLoading: false };
    default:
      return state;
  }
}

export default settings;