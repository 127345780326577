import React, { useEffect, useState, useCallback } from 'react';
import { EditMinor, EmailMajor } from '@shopify/polaris-icons';
import { Modal, TextField, Button, Stack, Icon, Banner } from "@shopify/polaris";
import { OnboardingStatus } from '../../../constants/common';
import earthPlanet from '../../../assets/images/earth-planet.png';
import AppRootStyles from './styles.module.scss';
import { getOnboarding } from '../../../redux/actions/onboardingActions';
import { connect } from 'react-redux';

const TimerModal = (props) => {
  const { getOnboarding, onboarding, auth } = props;
  const [isTimerPopupOpen, setIsTimerPopupOpen] = useState(false);
  const [time, setTime] = useState(172800000);
  const [intervalId, setIntervalId] = useState();

  useEffect(() => {
    getOnboarding();
  }, [])

  useEffect(() => {
    let changedTime;
    if (onboarding.status === OnboardingStatus.SETUP_STARTED && !onboarding.setupCompletedTime && onboarding.timeCounter) {
      // setTime(msToTime(onboarding.timeCounter));
      openModal();
      // changedTime = onboarding.timeCounter;
      // const intervalId = setInterval(() => {
      //   changedTime = (parseInt(changedTime / 1000) - 1) * 1000;
      //   setTime(msToTime(changedTime));
      // }, 1000);
      // setIntervalId(intervalId);
      // return () => {
      //   if (intervalId) {
      //     clearInterval(intervalId);
      //   }
      // }
    }
  }, [onboarding])

  const openModal = () => {
    setIsTimerPopupOpen(true);
    setTimeout(() => {
      const modals = document.getElementsByClassName("Polaris-Modal-Dialog");
      for(let item of modals) {
        item.removeAttribute("tabindex");
      }
    }, 500);
  }

  const msToTime = (duration) => {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)));

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    
    return {
      hours,
      minutes,
      seconds
    }
  }

  return (
    <Modal
      open={isTimerPopupOpen}
      title="Great! You have completed your part successfully."
      onClose={() => { }} >
      <Modal.Section>

        <div className={AppRootStyles["timer-popup-first-section"]}>
          {/* <img src={earthPlanet} alt="Globe" /> */}
          <svg version="1.1" className={AppRootStyles["success-svg"]} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle className={`${AppRootStyles["path"]} ${AppRootStyles["circle"]}`} fill="none" stroke="#04cea4" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <polyline className={`${AppRootStyles["path"]} ${AppRootStyles["check"]}`} fill="none" stroke="#04cea4" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
          </svg>
          <div className={AppRootStyles["title"]}><span className="bold-font">Your admin panel will be active once we complete the remaining setup</span> (within the next 3 to 4 business days)</div>
          {/* <div className={AppRootStyles["title"]}>Due to upcoming Black Friday and Cyber Monday Sales, <span className="bold-font">the app set up has been stopped till 1st Dec.</span> To compensate this delay, the trial period is extended from 15 days to 30 days. However, you can choose to re-install the app after 1st Dec.</div> */}
          {/* <div className={AppRootStyles["title"]}><span className="bold-font">Your admin panel will be active once we complete the remaining setup. We are having the festive time with our family and loved ones, so we will be back to assist you more enthusiastically 8th Nov, Monday onwards</span> (To compensate the delay in setup, we have increased the trial period of the app from 15 days to 30 days).</div> */}
        </div>
        {/* <div className={AppRootStyles["timer-popup-second-section"]}>
          {time.hours}<span>hr</span> : {time.minutes}<span>min</span> : {time.seconds}<span>sec</span>
        </div> */}
        {/* <div className={AppRootStyles["timer-popup-third-section"]}>We will be sending an email to <span>{onboarding.storeOwnerEmailId}</span> once the setup is done.</div> */}
        {/* <div className={AppRootStyles["timer-popup-fourth-section"]}>We have already created a ticket and sent an acknowledgement to <span className={AppRootStyles["link"]}>{onboarding.communicationEmailId}</span>.
You will be notified once the setup is done via the same ticket.</div> */}

        <div className={AppRootStyles["contact-us"]}>
          <Banner icon={EmailMajor}>
            <p>We have created a ticket and sent an acknowledgement to <span className={AppRootStyles["link"]}>{onboarding.communicationEmailId}</span>.
            You will be notified once the setup is done.</p>
          </Banner>
        </div>

      </Modal.Section>
    </Modal>
  )
};

const mapStateToProps = (state) => {
  return {
    onboarding: state.onboarding.onboarding,
    auth: state.auth
  };
}

const mapDispatchToProps = {
  getOnboarding
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimerModal);

// export default TimerModal;