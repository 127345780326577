import APIService from "./APIService";

const API_END_POINT = process.env.REACT_APP_ADMIN_API_ENDPOINT + "settings";

export default {
  getSettings() {
    return APIService.get(API_END_POINT);
  },
  updateSettings(putData) {
    return APIService.put(`${API_END_POINT}`, putData);
  },
}