import { GET_GLOBAL_MARKETS_BEGIN, GET_GLOBAL_MARKETS_SUCCESS, GET_GLOBAL_MARKETS_FAILURE } from '../actions/actionTypes';
import globalMarketsApi from "../../services/globalMarketsApi"

export const getGlobalMarketsBegin = () => {
  return { type: GET_GLOBAL_MARKETS_BEGIN }
}
export const getGlobalMarketsSuccess = (globalMarkets) => {
  return { type: GET_GLOBAL_MARKETS_SUCCESS, payload: { globalMarkets } }
}
export const getGlobalMarketsFailure = (error) => {
  return { type: GET_GLOBAL_MARKETS_FAILURE, payload: { error } }
}

export const getGlobalMarkets = () => (dispatch) => {
  dispatch(getGlobalMarketsBegin());

  return globalMarketsApi.getGlobalMarkets()
    .then((response) => {
      const globalMarkets = response.data;
      if(globalMarkets.length > 0) {
        globalMarkets.sort((a, b) => {
          if(a.regionName > b.regionName ) {
            return 1;
          } else if (a.regionName < b.regionName) {
            return -1;
          } else {
            return 0;
          };
        });
      }
      dispatch(getGlobalMarketsSuccess(globalMarkets));
    }).catch((error) => {
      dispatch(getGlobalMarketsFailure(error));
    })

}