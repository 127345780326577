import httpInterceptor from '../helpers/httpInterceptor';

export default {

    setHeader(key,value) {
      httpInterceptor.defaults.headers.common[key] = value;
    },

    removeHeader() {
      httpInterceptor.defaults.headers.common = {}
    },

    get(resource, params) {
        return httpInterceptor.get(resource, {params})
    },

    post(resource, data, params) {
        return httpInterceptor.post(resource, data, params)
    },

    put(resource, data) {
        return httpInterceptor.put(resource, data)
    },

    delete(resource, data) {
        return httpInterceptor.delete(resource, {data})
    },

    patch(resource, data) {
        return httpInterceptor.patch(resource, data)
    }
}
