import React from 'react';
import { Banner, Layout, Icon, Card, List, Stack, Button } from '@shopify/polaris';

const BulkDownload = (props) => {
  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Bulk Pricing Update"
        description="Use this option if you want to update all the product prices in bulk at once"
      >
        <Card sectioned>
          <div className="bulkDownload">
            <Stack>
              <Stack.Item>
                <List type="number">
                  <List.Item>Download the CSV file from here</List.Item>
                  <List.Item>Update the CSV file with your prices</List.Item>
                  <List.Item>Send the updated CSV file to <span className="emailLink">support@mlveda.com</span> and we will upload the prices for you within the next two to three business days.</List.Item>
                </List>
              </Stack.Item>
              <Stack.Item>
                <Button disabled={props.bulkDownloadApiCalled} loading={props.bulkDownloadApiCalled} onClick={() => props.downloadCSV()}>Download CSV</Button>
              </Stack.Item>
            </Stack>
            <div className="mt-20"></div>
            <Banner status="info">
              <p><span className="bold-font">Important Note:</span> Do not update anything except the product prices in the CSV file. </p>
            </Banner>
          </div>
        </Card>
      </Layout.AnnotatedSection>
    </Layout>
  );
};



export default BulkDownload;