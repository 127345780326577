import React, { useState, useEffect, useCallback } from 'react';
import { Banner, Button, Card, DataTable, Frame, Page, Toast, Modal, Tooltip, Badge, Link, Stack, Icon, List } from '@shopify/polaris';
import MarketForm from '../../components/MarketForm/index';
import { connect } from "react-redux";
import { getMarkets, createMarket, updateMarket, deleteMarket } from '../../redux/actions/marketActions';
import roundingApi from '../../services/roundingApi';
import productApi from '../../services/productsApi';
import marketApi from '../../services/marketApi';
import { QuestionMarkMajor, CircleInformationMajor, EditMajor } from '@shopify/polaris-icons';
import { FormMode } from '../../helpers/constant';
import FrozeAdminPanel from '../FrozeAdminPanel';
import MCPFooterHelp from '../../components/common/MCPFooterHelp';
import StoreAccessContainer from '../../containers/onboarding/StoreAccessContainer';

const initialMarketForm = {
    regionName: '',
    displayCurrencyCodes: [],
    setCurrencyCode: '',
}
const MarketsContainer = props => {
    const { defaultCountryOptions, remainingMarketOptions, markets, isMarketLoading, getMarkets, createMarket, updateMarket, deleteMarket, currencies, error, globalMarketOptions } = props;
    
    const [isMarketFormOpen, setIsMarketFormOpen] = useState(false);
    const [marketForm, setMarketForm] = useState(initialMarketForm);
    const [moreActionActivator, setMoreActionActivator] = useState({});
    const [formMode, setFormMode] = useState(FormMode.ADD)
    const [isShowToast, setIsShowToast] = useState(false);
    const [isShowErrorToast, setIsShowErrorToast] = useState(false);
    const [isWarningModelOpen, setIsWarningModelOpen] = useState(false);
    const [tmpSetCurrency, setTmpSetCurrency] = useState(null);
    const [roundingRules, setRoundingRules] = useState([]);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [timer, setTimer] = useState(0);
    const [deleteMarketApiCalled, setDeleteMarketApiCalled] = useState(false);
    const [openFrozeAdminPanel, setOpenFrozeAdminPanel] = useState(false);
    const [onSaveApiCalled, setOnSaveApiCalled] = useState(false);
    const [setCurrencyOptions, setSetCurrencyOptions] = useState([]);
    const [setCurrencyApiCalled, setSetCurrencyApiCalled] = useState(false);
    const [defaultDisplayCurrencyOptions, setDefaultDisplayCurrencyOptions] = useState([]);
    const [displayCurrencyOptions, setDisplayCurrencyOptions] = useState([]);
    const [displayCurrencyApiCalled, setDisplayCurrencyApiCalled] = useState(false);
    const [displayCurrencyInputValue, setDisplayCurrencyInputValue] = useState('');
    const [managedBySPBanner, setManageBySpBanner] = useState(false);
    const [apiCalled, setApiCalled] = useState(true);
    const [ischeckoutSupporetedCurrenciesModalOpen, setIscheckoutSupporetedCurrenciesModalOpen] = useState(false);
    const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
    const [isCommonReasonsModalOpen, setIsCommonReasonsModalOpen] = useState(false);
    const [isBannerClosed, setIsBannerClosed] = useState(false);

    useEffect(() => {
        if (markets.length > 0) {
            setMoreActionActivator((oldVal) => {
                const tmpObject = {}
                markets.forEach((m) => {
                    tmpObject[m.id] = false;
                    m.isLoading = false;
                })
                return tmpObject
            })
        }
    }, [markets]);

    useEffect(() => {
      if(error && error.error) {
        if(error.error !== "Invalid Session") {
          setIsShowErrorToast(true);
        }
      }
    }, [error])

    useEffect(() => {
        if (markets.length === 0) {
            getMarkets().then(() => {
              setApiCalled(false);
            });
        } else {
          setApiCalled(false);
        }
        fetchRoundings();
    }, [])

    const fetchRoundings = () => {
      roundingApi.getStoreRoundings().then(roundingRules => {
        setRoundingRules((roundingRules.data && roundingRules.data.rounding_rules) || []);
      });
    }

    const fetchSetCurrencies = async (regionName) => {
      setSetCurrencyApiCalled(true);
      const setCurrencies = await marketApi.getSetCurrencies(regionName);
      const tempCurrencies = [];
      for (let c of setCurrencies.data) {
        const currencyName = currencies.find(i => i.code === c);
        if(currencyName && currencyName.name) {
          tempCurrencies.push({value: c, label: `${currencyName.name} (${c})`});
        } else {
          tempCurrencies.push({value: c, label: c});
        }
      }
      setSetCurrencyOptions(tempCurrencies);
      if(tempCurrencies.length > 0) {
        setMarketForm((oldVal) => ({ ...oldVal, setCurrencyCode: tempCurrencies[0].value }));
      }
      setSetCurrencyApiCalled(false);
      return tempCurrencies;
    }

    const fetchDisplayCurrencies = async (regionName) => {
      setDisplayCurrencyApiCalled(true);
      setManageBySpBanner(false);
      const displayCurrencies = await  marketApi.getDisplayCurrencies(regionName);
      const tempCurrencies = [];
      for (let c of displayCurrencies.data) {
        const currencyName = currencies.find(i => i.code === c);
        if(currencyName && currencyName.name) {
          tempCurrencies.push({value: c, label: `${currencyName.name} (${c})`});
        } else {
          tempCurrencies.push({value: c, label: c});
        }
      }
      if(formMode === FormMode.ADD && displayCurrencies.data && displayCurrencies.data.length > 0) {
        setMarketForm((oldVal) => ({ ...oldVal, displayCurrencyCodes: [displayCurrencies.data[0]] }));
      }
      setDisplayCurrencyOptions(tempCurrencies);
      setDefaultDisplayCurrencyOptions(tempCurrencies);
      if(displayCurrencies.data.length > 0) {
        const currencyFound = roundingRules.find(i => i.currency === displayCurrencies.data[0]);
        if(currencyFound && currencyFound.shopify_enabled) {
          setManageBySpBanner(true);
        }
      }
      setDisplayCurrencyApiCalled(false);
      return tempCurrencies;
    }

    const updateDisplayCurrencyText = useCallback((value) => {
      setDisplayCurrencyInputValue(value);
  
        if (value === '') {
          setDisplayCurrencyOptions(defaultDisplayCurrencyOptions);
          return;
        }
  
        const filterRegex = new RegExp(value, 'i');
        const resultOptions = defaultDisplayCurrencyOptions.filter((option) =>
          option.label.match(filterRegex),
        );
        let endIndex = resultOptions.length - 1;
        if (resultOptions.length === 0) {
          endIndex = 0;
        }
        setDisplayCurrencyOptions(resultOptions);
      },
      [defaultDisplayCurrencyOptions],
    );

    const handleFormStateChange = (key, val) => {
        if(key === "regionName") {
          const region = globalMarketOptions.find(i => i.regionName === val);
          if(region) {       
            setMarketForm((oldVal) => ({ ...oldVal, regionType: region.regionType }));
          }
        }
        setMarketForm((oldVal) => ({ ...oldVal, [key]: val }));
        if(key === 'displayCurrencyCodes') {
          let managesBySP = false;
          for(let c of val) {
            const currencyFound = roundingRules.find(i => i.currency === c);
            if(currencyFound && currencyFound.shopify_enabled) {
              managesBySP = true;
              break;
            } else {
              managesBySP = false;
            }
          }
          setManageBySpBanner(managesBySP);
        }
        if(key === 'regionName') {
          fetchSetCurrencies(val);
          fetchDisplayCurrencies(val);      
        }
    }

    const removeDisplayCurrencies = useCallback(
      (tag) => () => {
        const options = [...marketForm.displayCurrencyCodes];
        options.splice(options.indexOf(tag), 1);
        let managesBySP = false;
        for(let c of options) {
          const currencyFound = roundingRules.find(i => i.currency === c);
          if(currencyFound && currencyFound.shopify_enabled) {
            managesBySP = true;
            break;
          } else {
            managesBySP = false;
          }
        }
        setManageBySpBanner(managesBySP);
        setMarketForm((oldVal) => ({ ...oldVal, displayCurrencyCodes: options }));
      },
      [marketForm.displayCurrencyCodes],
    );

    const closeModal = () => {
      setIsWarningModelOpen(false);
    }

    const openModal = (val) => {
      setTmpSetCurrency(val);
      setIsWarningModelOpen(true);
    }

    const handleSave = async () => {
        // Dispatch createMarket action to create new market
        // Then hide the form
        let res;
        setOnSaveApiCalled(true);
        if (formMode === FormMode.ADD) {
          const marketFormClone = Object.assign({}, marketForm);
          delete marketFormClone.isLoading;
          res = await createMarket(marketFormClone).catch(createMarketErr => {
            setIsShowErrorToast(true);
          });
          await getMarkets();
        }
        else {
          const marketFormClone = Object.assign({}, marketForm);
          delete marketFormClone.isLoading;
          res = await updateMarket(marketFormClone).catch(updateMarketErr => {
            setIsShowErrorToast(true);
          });
        }
        if(res) {
          // const roundings = {
          //   rounding_rules:[
          //     {currency:marketForm.setCurrencyCode}
          //   ]
          // };
          // for(const dc of marketForm.displayCurrencyCodes) {
          //   roundings.rounding_rules.push({currency: dc});
          // }
          // const roundingsRes = await roundingApi.updateStoreRoundings(roundings).catch(roundingErr => {
          //   setIsShowErrorToast(true);
          // });
          // if(roundingsRes) {
          //   setIsShowToast(true);
          // }
          setIsShowToast(true);
          setIsMarketFormOpen(false);
          setOnSaveApiCalled(false);
        } else {
          setOnSaveApiCalled(false);
        }
    }

    const openConfirmationDelete = () => {
      productApi.getProductCount().then(countData => {
        const count = countData.data.count || 0;
        setTimer(count * 2 * 1000);
        setIsWarningModelOpen(false);
        setIsConfirmationModalOpen(true);
      }).catch(error => {
        if (error.error !== 'Invalid Session') {
          setIsShowErrorToast(true);
        }
      })
      
    }

    const closeConfirmationModal = () => {
      setIsConfirmationModalOpen(false);
    }

    const handleDeleteRegion = async () => {
      setDeleteMarketApiCalled(true);
      setFormMode(FormMode.DELETE);
      await deleteMarket(marketForm);
      setIsShowToast(true);
      setIsMarketFormOpen(false);
      setDeleteMarketApiCalled(false);
      closeConfirmationModal();
      if(timer > 2000) {
        setOpenFrozeAdminPanel(true);
      }
    }

    const closeFreezeAdminPanel = () => {
      setOpenFrozeAdminPanel(false);
    }

    /**
     * Hide the more action menu for the rest of the markets expect selected
     */
    const toggleActive = (selectedMarket, toggleAction) => {
        setMoreActionActivator((oldVal) => {
            const tmpObject = {};
            markets.forEach((m) => {
                if (m.id !== selectedMarket.id || toggleAction === 'onClose')
                    tmpObject[m.id] = false
                else
                    tmpObject[selectedMarket.id] = !tmpObject[selectedMarket.id]
            })

            return tmpObject
        })
    }
    const handleImportedAction = () => {

    }

    const handleEditMarket = async (selectedMarket) => {
        selectedMarket.isLoading = true;
        setMoreActionActivator({ ...moreActionActivator, [selectedMarket.id]: false })
        const toBeUpdatedMarket = { ...markets.find((m) => m.id === selectedMarket.id) }
        let tempDisplayCurrencies;
        if(toBeUpdatedMarket.isPrimaryMarket && !isROWAvailable) {
          tempDisplayCurrencies = await fetchDisplayCurrencies("Rest of The World");
        } else {
          tempDisplayCurrencies = await fetchDisplayCurrencies(toBeUpdatedMarket.regionName);
        }
        setDisplayCurrencyInputValue('');
        const tempSetCurrencies = await fetchSetCurrencies(toBeUpdatedMarket.regionName, toBeUpdatedMarket.setCurrencyCode);
        const selectedSetCurrency = tempSetCurrencies.find(i => i.value === toBeUpdatedMarket.setCurrencyCode);
        if(!selectedSetCurrency) {
          toBeUpdatedMarket.setCurrencyCode = setCurrencyOptions.value;
        }

        const selectedDisplayCurrency = [];
        for(let i of toBeUpdatedMarket.displayCurrencyCodes) {
          const found = tempDisplayCurrencies.find(j => j.value === i);
          if(found) {
            selectedDisplayCurrency.push(i);
          }
        }
        toBeUpdatedMarket.displayCurrencyCodes = selectedDisplayCurrency;
        setMarketForm(toBeUpdatedMarket);
        setFormMode(FormMode.EDIT);
        setIsMarketFormOpen(true);
        selectedMarket.isLoading = false;
    }
    const openMarketForm = () => {
        setDisplayCurrencyInputValue('');
        setDisplayCurrencyOptions([]);
        setMarketForm({ ...initialMarketForm })
        setFormMode(FormMode.ADD)
        setIsMarketFormOpen(true)
    }

    // const getMoreActionMarkup = (market) => {
    //     return (
    //         <Popover

    //             active={moreActionActivator[market.id]}
    //             activator={
    //                 <Tooltip content="More actions" dismissOnMouseOut>
    //                     <Button onClick={(e) => { toggleActive(market) }}
    //                         plain
    //                         icon={MobileVerticalDotsMajor}></Button>
    //                 </Tooltip>}
    //             onClose={(e) => { toggleActive(market, 'onClose') }}
    //         >
    //             <ActionList
    //                 items={[
    //                     {
    //                         content: 'Edit',
    //                         onAction: () => { handleEditMarket(market) },
    //                     },
    //                     {
    //                         content: 'Edit prices',
    //                         onAction: () => { props.history.push("/app/product-prices") },
    //                     },
    //                     // {
    //                     //     content: 'Delete',
    //                     //     onAction: () => { handleEditMarket(market) },
    //                     // },
    //                 ]}
    //             />
    //         </Popover>)
    // }

    const getMoreActionMarkup = (market) => {
      return (
        <Button plain loading={market.isLoading} disabled={market.isLoading} onClick={() => { handleEditMarket(market) }}>Settings</Button>
      )
    }

    const getBadge = (market) => {
      let isROWAvailable = false;
      for(let m of markets) {
        if(m.regionCode === "intl") {
          isROWAvailable = true;
          break;
        }
      }
      return (
        <span>
          <span className="cursor-pointer" onClick={() => {props.history.push("/app/product-prices");}}>{market.regionName}{!isROWAvailable && market.isPrimaryMarket && <span> + Rest of the world</span>}</span>
          {market.isPrimaryMarket && <span className="ml-10 cursor-pointer">
            <Tooltip content="Primary market represents the location(s) that you choose to keep selling at the prices before you installed our app.">
              <Badge status="success">Primary</Badge>
            </Tooltip>
          </span>}
        </span>
      )
    }

    const getSetCurrencyTextBox = (setCurrencyCode) => {
      // const setCurrencyName = currencies.find(i => i.code === setCurrencyCode);
      return (
        <div className="textField" title={setCurrencyCode}>{setCurrencyCode}</div>
      )
    }

    const getDisplayCurrencyTextBox = (displayCurrencyCodes) => {
      // const displayCurrencies = [];
      // for(let c of displayCurrencyCodes) {
      //   const displayCurrencyName = currencies.find(i => i.code === c);
      //   if(displayCurrencyName && displayCurrencyName.name) {
      //     displayCurrencies.push(displayCurrencyName.name);
      //   }
      // }
      const supportedCurrencies = [];
      for(let c of displayCurrencyCodes) {
        if(props.supportedCheckoutCurrencies.indexOf(c) >= 0) {
          supportedCurrencies.push(c);
        }
      }
      return (
        <div className="displayCurrencyInTable">
          <div className="textField" title={displayCurrencyCodes.join(", ")}>{displayCurrencyCodes.join(", ")}</div>
          <span className="tooltipSpan">
            <Tooltip content={supportedCurrencies.length > 0 ? 
              (supportedCurrencies.length === displayCurrencyCodes.length ? 
                `As per your checkout and payment gateway settings, customers browsing website in ${supportedCurrencies.join(', ')} can pay directly.` 
                : `As per your checkout and payment gateway settings, customers browsing website in ${supportedCurrencies.join(', ')} can pay directly. Others will pay in your store currency.`) : 
              `As per your checkout and payment gateway settings, customers can only pay in your store currency.`}>
              <Icon source={QuestionMarkMajor} color="base" />
            </Tooltip>
          </span>
        </div>
      )
    }

    const marketRows = markets.map((m, index) => [index + 1, getBadge(m), getSetCurrencyTextBox(m.setCurrencyCode), getDisplayCurrencyTextBox(m.displayCurrencyCodes), getMoreActionMarkup(m)])

    let isROWAvailable = false;
    for(let m of markets) {
      if(m.regionCode === "intl") {
        isROWAvailable = true;
        break;
      }
    }

    return (
        <div className="market-container">
            {!isBannerClosed && <div className="marketContactUsBanner">
                <Banner status="critical" title="Important Information" onDismiss={() => { setIsBannerClosed(true) }}>
                  <p>Please test the app after setting prices for any <span className='bold-font'>one product</span> from the Prices tab above (<Link url='https://youtu.be/vBpIU1Df2Pg?start=123' external>Refer this video</Link>). Common <Link onClick={() => setIsCommonReasonsModalOpen(true)}>reasons</Link> for issues. <Link onClick={() => setIsContactUsModalOpen(true)}>Give us access</Link> if you find any issue with our app.</p>
                </Banner>
            </div>}
            <Page
                title="Your markets"
                primaryAction={{
                    content: '+ Create market',
                    onAction: () => { openMarketForm() }
                }}
            >

                {!apiCalled && <Card>
                      <DataTable
                          columnContentTypes={[
                              'text',
                              'text',
                              'text',
                              'text',
                              'text'
                          ]}
                          headings={[
                              'No.',
                              'Target market',
                              'Prices set in',
                              'Prices seen in',
                              ''
                          ]}
                          rows={marketRows}

                      />     
                  </Card>}

                <div className="marketBanner">
                  <Banner>
                    <p>As per your checkout and payment gateway settings, customers browsing website in <Link onClick={() => setIscheckoutSupporetedCurrenciesModalOpen(true)}>these currencies</Link> can pay directly. Others will pay in your store currency. Please contact <span className="emailLink">support@mlveda.com</span> for more details.</p>
                  </Banner>
                </div>
                {/* {!isROWAvailable && <div className="mt-10 restOfWorldMessage"><span>* Prices of rest of the world will be same as primary market</span><Tooltip content={`If you want to set up different prices for rest of the world then create a new market and choose "Rest of The World" from the country/continent dropdown.`}>
                    <Icon source={CircleInformationMajor} color="base" />
                  </Tooltip> </div>} */}
                <MCPFooterHelp />
            </Page>

            {isMarketFormOpen && <MarketForm
                marketForm={marketForm}
                onFormStateChange={handleFormStateChange}
                defaultCountryOptions={formMode === FormMode.EDIT ? defaultCountryOptions : remainingMarketOptions}
                onSave={handleSave}
                handleDeleteRegion={handleDeleteRegion}
                formMode={formMode}
                onCloseForm={() => { setIsMarketFormOpen(false) }}
                isWarningModelOpen={isWarningModelOpen}
                openModal={openModal}
                closeModal={closeModal}
                tmpSetCurrency={tmpSetCurrency}
                openConfirmationDelete={openConfirmationDelete}
                isConfirmationModalOpen={isConfirmationModalOpen}
                timer={timer}
                closeConfirmationModal={closeConfirmationModal}
                deleteMarketApiCalled={deleteMarketApiCalled}
                onSaveApiCalled={onSaveApiCalled}
                setCurrencyApiCalled={setCurrencyApiCalled}
                setCurrencyOptions={setCurrencyOptions}
                displayCurrencyApiCalled={displayCurrencyApiCalled}
                displayCurrencyOptions={displayCurrencyOptions}
                updateDisplayCurrencyText={updateDisplayCurrencyText}
                displayCurrencyInputValue={displayCurrencyInputValue}
                removeDisplayCurrencies={removeDisplayCurrencies}
                managedBySPBanner={managedBySPBanner}
                supportedCheckoutCurrencies={props.supportedCheckoutCurrencies}
                isROWAvailable={isROWAvailable}
            />}

            {openFrozeAdminPanel && <FrozeAdminPanel timeInMs={timer} closeFreezeAdminPanel={closeFreezeAdminPanel} storeId={props.storeId} />}
            {isShowToast && <Frame>
                <Toast
                    duration={2000}
                    content={(formMode === FormMode.ADD) ? 'Market has been created successfully.' : (formMode === FormMode.EDIT ? 'Market has been updated successfully.': 'Market has been deleted successfully.')}
                    onDismiss={() => { setIsShowToast(false) }} />
            </Frame>}

            {isShowErrorToast && <Frame>
                <Toast
                    error
                    duration={1500}
                    content={"Something went wrong!"}
                    onDismiss={() => { setIsShowErrorToast(false) }} />
            </Frame>}

            <Modal
              large={true}
              title="Checkout supported currencies"
              open={ischeckoutSupporetedCurrenciesModalOpen}
              primaryAction={{
                content: "Close",
                onAction: () => setIscheckoutSupporetedCurrenciesModalOpen(false)
              }}
            >
              <Modal.Section>
                <div className="supportCheckoutCurrencies">
                  <Stack distribution="fillEvenly">
                    {props.supportedCheckoutCurrencies.map((currency) => {
                      const currencyName = props.currencies.find(i => i.code === currency);
                      return (
                      <Stack.Item key={currency}>{currencyName ? currencyName.name: ""}{currencyName ? " (" + currency + ")" : " " + currency}</Stack.Item>
                      )
                    })}
                  </Stack>
                  <p className="mt-15"><span className="bold-font">Note:</span> Others will pay in your store currency. Please write to us at <span className="emailLink">support@mlveda.com</span> if you have any queries.</p>
                </div>
              </Modal.Section>
            </Modal>

            <Modal
              title="Give us access"
              open={isContactUsModalOpen}
            >
              <Modal.Section>
                <StoreAccessContainer setIsContactUsModalOpen={setIsContactUsModalOpen} />
              </Modal.Section>
            </Modal>

            <Modal
              title="Common reasons for issues"
              open={isCommonReasonsModalOpen}
              primaryAction={{
                content: "OK",
                onAction: () => setIsCommonReasonsModalOpen(false)
              }}
            >
              <Modal.Section>
                <List type='number'>
                  <List.Item>Some apps on your store may be conflicting with our app.</List.Item>
                  <List.Item>Customizations done on your theme may be conflicting with our app.</List.Item>
                  <List.Item>Our app <Link url={`https://${props.storeId}/${process.env.REACT_APP_EXTENSION_URL}`}
            external>Embed Block</Link> may have been disabled on your theme.</List.Item>
                </List>
              </Modal.Section>
            </Modal>

        </div>
    );
};


const mapStateToProps = (state) => {
    const { globalMarketOptions, countryOptions } = state.globalMarkets
    const storeMarketsRegionNames = state.markets.markets.map((m) => m.regionName)
    const remainingMarketOptions = countryOptions.filter((mo) => !storeMarketsRegionNames.includes(mo.value))
    return {
        isMarketLoading: state.markets.isLoading,
        markets: state.markets.markets,
        error: state.markets.error,
        onboarding: state.onboarding.onboarding,
        globalMarketOptions,
        defaultCountryOptions: countryOptions,
        currencies: state.currencies.currencies,
        remainingMarketOptions,
        storeId: state.auth && state.auth.storeDetails && state.auth.storeDetails.myshopify_domain,
        supportedCheckoutCurrencies: state.auth && state.auth.storeDetails && state.auth.storeDetails.enabled_presentment_currencies
    }
}
const mapDispatchToProps = {
    getMarkets,
    createMarket,
    updateMarket,
    deleteMarket
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketsContainer);
