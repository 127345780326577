import React, { useState, useCallback, useEffect } from 'react';
import { connect } from "react-redux";
import { Frame, Toast, Button, RadioButton, Modal, TextContainer, TextField, Link, Icon, Stack } from '@shopify/polaris';
import { updateOnboarding, getOnboarding } from '../../../redux/actions/onboardingActions';
import staffInfo from '../../../assets/images/staffInfo.png';
import staffPermission from '../../../assets/images/staffPermission.png';
import Styles from './styles.module.scss';
import { ChevronRightMinor, ChevronDownMinor } from '@shopify/polaris-icons';


const StoreAccessContainer = ({ updateOnboarding, getOnboarding, ...props }) => {
    const { error, onboarding, isLoading } = props;
    const [accessType, setAccessType] = useState();
    const [isShowErrorToast, setIsShowErrorToast] = useState(false);
    const [apiCalled, setApiCalled] = useState(false);
    const [staffAccessModalOpen, setStaffAccessModalOpen] = useState(false);
    const [currentStepNumber, setCurrentStepNumber] = useState(1);
    const [collaborativeAccessCode, setCollaborativeAccessCode] = useState('');
    const [openCollaborativeCodePanel, setOpenCollaborativeCodePanel] = useState(false);

    useEffect(() => {
      if(error && error.error) {
        if(error.error !== "Invalid Session") {
          setIsShowErrorToast(true);
          setApiCalled(false);
        }
      }
    }, [error]);

    useEffect(() => {
      if(onboarding && onboarding.accessType) {
        setAccessType(onboarding.accessType); 
      }
      if(onboarding && onboarding.collaborativeAccessCode) {
        setCollaborativeAccessCode(onboarding.collaborativeAccessCode); 
      }
    }, []);

    const handleStateChange = useCallback((_checked, newVal) => {
        setAccessType(newVal)
    })

    const finishOnboarding = async () => {
      setApiCalled(true);
      const toBeUpdatedData = {
          // status: OnboardingStatus.ONBOARDING_COMPLETED,
          accessType: accessType,
          collaborativeAccessCode: accessType === "COLLABORATIVE_ACCESS" ? collaborativeAccessCode : null
      }
      // Dispatch the action to update the onboarding detail
      await updateOnboarding(toBeUpdatedData);
      // await getOnboarding();
    }

    const handleChange = useCallback(() => {
      setStaffAccessModalOpen(!staffAccessModalOpen);
      setCurrentStepNumber(1);
    }, [staffAccessModalOpen]);

    const handleStepChange = (type) => {
      if(type === "increase") {
        setCurrentStepNumber(currentStepNumber + 1);
      } else {
        setCurrentStepNumber(currentStepNumber - 1);
      }
    };

    const handleTextChange = useCallback((newValue) => setCollaborativeAccessCode(newValue), []);

    return (
        <div>
            <div>
                
                <RadioButton
                    disabled={apiCalled}
                    label="I want to receive collaborator access request (recommended)"
                    id="COLLABORATIVE_ACCESS"
                    name="accounts"
                    checked={accessType === 'COLLABORATIVE_ACCESS'}
                    onChange={handleStateChange}
                    helpText={accessType === 'COLLABORATIVE_ACCESS' ? 
                    <>
                      <span>We will be sending a collaborator request to <span className="emailLink">{props.auth.storeDetails.email}</span> within the next 24 hours. Please approve it after receiving it.</span>
                    </>
                    : 
                    ""}
                />
                {accessType === 'COLLABORATIVE_ACCESS' && 
                  <div className="collaborativeCode">
                    <Link onClick={() => { setOpenCollaborativeCodePanel(!openCollaborativeCodePanel)}}>
                      Enter your collaborator access code (if you have)
                      {!openCollaborativeCodePanel && <Icon source={ChevronRightMinor} />}
                      {openCollaborativeCodePanel && <Icon source={ChevronDownMinor} />}
                    </Link>
                    {openCollaborativeCodePanel && <TextField value={collaborativeAccessCode} onChange={handleTextChange} />}
                  </div>
                }
                <br />
                <RadioButton
                    disabled={apiCalled}
                    label="I want to give limited staff access"
                    checked={accessType === 'STAFF_ACCESS'}
                    id="STAFF_ACCESS"
                    name="accounts"
                    onChange={handleStateChange}
                />
                {/* {accessType === 'STAFF_ACCESS' && <StaffAccessInfo shopName={props.auth.storeDetails.myshopify_domain} key={'STAFF_ACCESS'} />} */}
                {accessType === 'STAFF_ACCESS' && <div className="mt-15"><Button primary onClick={handleChange}>Do these steps</Button></div>}
                {/* {accessType === 'COLLABORATIVE_ACCESS' && <StaffAccessInfo shopName={props.auth.storeDetails.myshopify_domain} key={'COLLABORATIVE_ACCESS'} />} */}

                {/* <div className="staffAccessContactMsg">If you are facing any problem then please ping us in the chat button given in the bottom right corner. If we are offline then please send us an email to{' '}<span className="emailLink">support@mlveda.com</span>{' '}and we will revert you back within 24 hours.</div> */}
            </div>
            <div className="onboarding-footer-btn-main">
              <Stack distribution="fillEvenly">
                <Button fullWidth onClick={() => { props.setIsContactUsModalOpen(false) }}>Cancel</Button>
                <Button
                    fullWidth
                    loading={apiCalled}
                    disabled={apiCalled}
                    primary
                    onClick={finishOnboarding}
                  >Done</Button>
              </Stack>
            </div>
            {isShowErrorToast && <Frame>
              <Toast
                  error
                  duration={1500}
                  content={"Something went wrong!"}
                  onDismiss={() => { setIsShowErrorToast(false) }} />
          </Frame>}

          <Modal
            open={staffAccessModalOpen}
            onClose={handleChange}
            title={`Step ${currentStepNumber} of 3`}
            primaryAction={{
              content: currentStepNumber === 3 ? "Done" : "Next",
              onAction: () => currentStepNumber === 3 ? handleChange() : handleStepChange('increase'),
            }}
            secondaryActions={currentStepNumber > 1 ? [
              {
                content: 'Cancel',
                onAction: () => handleChange(),
              },
              {
                content: 'Previous',
                onAction: () => handleStepChange('decrease'),
              }
            ] : [
              {
                content: 'Cancel',
                onAction: () => handleChange(),
              }
            ]}
          >
            <Modal.Section>
              <TextContainer>
              {currentStepNumber === 1 && <div>
                <div>Click on <span className="underline blue-color cursor-pointer" onClick={() => window.open(`https://${props.auth.storeDetails.myshopify_domain}/admin/settings/account/new`, "_blank")}>this link</span> (It will open in a new tab)</div>
                </div>}
                {currentStepNumber === 2 && <div>
                  <div>Copy the below details and paste in the respective fields of Add staff section</div>
                    <ul>
                        <li>First name = Multi Country Pricing</li>
                        <li>Second name = App</li>
                        <li>Email = shopify@mlveda.com</li>
                    </ul>
                    <img className={Styles["staffAccesImg"]} src={staffInfo} alt="Staff Info" />
                </div>}
                {currentStepNumber === 3 && <div>
                  <div>In the permission section, select Orders, Products, Apps and channels, Themes and Manage settings and click on <span className="bold-font">Send invite</span> button below.</div>
                  <img className={`mt-15 ${Styles["staffAccesImg"]}`} src={staffPermission} alt="Staff Permission" />
                </div>}
              </TextContainer>
            </Modal.Section>
          </Modal>

        </div>
    );
};

StoreAccessContainer.propTypes = {

};

const mapStateToProps = (state) => {
    return {
      error: state.onboarding.error,
      auth: state.auth,
      onboarding: state.onboarding.onboarding,
      // isLoading: state.onboarding.isLoading
    }
}
const mapDispatchToProps = {
    updateOnboarding,
    getOnboarding
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StoreAccessContainer);
