const MAX_ALLOWED_VARIANTS = 100;

const checkIsEditable = (product) => {
  if (product.options.length >= 3) {
    const optionsHavingMlveda = product.options.filter((o) => o.name == "mlveda_country")
    if (optionsHavingMlveda.length > 0)
      return true
    return false
  }
  return true
}

export const transformProducts = (products, storeMarkets, storeRounding) => {
  const toBeRenderedProducts = []
  let primayMarket;
  const d = storeMarkets.find(i => i.isPrimaryMarket);
  if(d) {
    primayMarket = d.regionCode;
  }
  products.forEach((product) => {
    // Get the unique list of variants of individual product
    const uniqueVariants = [...new Set(product.variants.map(item => item.title))]
    // Iterate over each variant
    uniqueVariants.forEach((variantTitle) => {
      // Iterate over each store market and make flat array of it
      storeMarkets.forEach((storeMarket) => {      
        const foundVariantIndex = product.variants.findIndex((v) => v.title === variantTitle && v.region_code === storeMarket.regionCode);
        let rule = null;
        if(storeRounding && storeRounding.rounding_rules && storeRounding.rounding_rules.length > 0) {
          rule = storeRounding.rounding_rules.find(i => i.currency === storeMarket.setCurrencyCode);
        }
        if (foundVariantIndex > -1) {
          setToBeRenderedProducts(toBeRenderedProducts, product, variantTitle, storeMarket.regionCode, storeMarket.setCurrencySymbol, storeMarket.setCurrencyCode, product.inconsistent_state ? product.inconsistent_state : false, rule, primayMarket, product.variants[foundVariantIndex])
        } else {
          setToBeRenderedProducts(toBeRenderedProducts, product, variantTitle, storeMarket.regionCode, storeMarket.setCurrencySymbol, storeMarket.setCurrencyCode, product.inconsistent_state ? product.inconsistent_state : false, rule, primayMarket)
        }
      })
    })
    check100VariantValidation(toBeRenderedProducts, product.id, storeMarkets, product)
  })
  return toBeRenderedProducts
}

const check100VariantValidation = (rowData, productId, storeMarkets, product) => {
  const variants = rowData.filter((r) => r.productId == productId);
  if (product.options.length >= 3) {
    const optionsHavingMlveda = product.options.filter((o) => o.name == "mlveda_country");
    if (optionsHavingMlveda.length <= 0) {
      for (const market of storeMarkets) {
          for (const v of variants) {
            v[`${market.regionCode}_invalid_for_hundred_tooltip`] = "Exceeded Shopify limit of maximum 3 options per product.";
          }
      }
      return;
    }
  }

  const maxAllowedMarkets = Math.floor(MAX_ALLOWED_VARIANTS / variants.length)
  let marketsHavingValue = 0
  for (const market of storeMarkets) {
    // Check any variant for this market have a value
    let variantsHavingVal = variants.filter((v) => v[`${market.regionCode}_price`])

    if (variantsHavingVal.length > 0) {
      marketsHavingValue += 1
    }
  }

  if (marketsHavingValue >= maxAllowedMarkets) {
    for (const market of storeMarkets) {
      let variantsNotHavingVal = variants.filter((v) => !v[`${market.regionCode}_price`])
      if (variantsNotHavingVal.length === variants.length) {
        for (const v of variantsNotHavingVal) {
          v[`${market.regionCode}_invalid_for_hundred`] = true;
          v[`${market.regionCode}_invalid_for_hundred_tooltip`] = "Exceeded Shopify limit of maximum 100 variants per product.";
        }
      }
      let variantsHavingVal = variants.filter((v) => v[`${market.regionCode}_price`])
      for (const v of variantsHavingVal) {
        v[`${market.regionCode}_invalid_for_hundred`] = false;
        v[`${market.regionCode}_invalid_for_hundred_tooltip`] = "";
      }
    }
  } else {
    for (const market of storeMarkets) {
      for (const v of variants) {
        v[`${market.regionCode}_invalid_for_hundred`] = false;
        v[`${market.regionCode}_invalid_for_hundred_tooltip`] = "";
      }
    }
  }
}

const setToBeRenderedProducts = (toBeRenderedProducts, product, variantTitle, storeMarketCode, currencySymbol, currencyCode, inconsistentProduct, rule, primayMarket, variant = null) => {
  // If title of variant is blank then replace it with the product title
  const newVariantTitle = variantTitle || product.title
  const foundIndex = toBeRenderedProducts.findIndex((element) => element.variantTitle === newVariantTitle && element.productId === product.id)
  if (foundIndex > -1) {
    let foundObj = toBeRenderedProducts[foundIndex]
    // Iterate over the market
    toBeRenderedProducts[foundIndex] = {
      ...foundObj,
      [`${storeMarketCode}_price`]: variant && variant.price != undefined && variant.price.toString().trim().length > 0 ? variant.price.toString() : "",
      [`${storeMarketCode}_sku`]: variant && variant.sku ? variant.sku : "",
      [`${storeMarketCode}_compare_at_price`]: variant && variant.compare_at_price != undefined && variant.compare_at_price.toString().trim().length > 0 ? variant.compare_at_price.toString() : "",
      [`${storeMarketCode}_invalid`]: false,
      [`${storeMarketCode}_invalid_for_hundred`]: false,
      [`${storeMarketCode}_invalid_for_hundred_tooltip`]: "",
      [`${storeMarketCode}_currency_symbol`]: currencySymbol,
      [`${storeMarketCode}_currency_code`]: currencyCode,
      [`${storeMarketCode}_rule`]: rule
    }
  } else {
    toBeRenderedProducts.push({
      productId: product.id,
      productTitle: product.title,
      variantTitle: newVariantTitle,
      productImage: product.image,
      inconsistentProduct: inconsistentProduct,
      originalVariantTitle: variantTitle,
      primayMarket: primayMarket,
      isEditable: checkIsEditable(product),
      [`${storeMarketCode}_price`]: variant && variant.price != undefined && variant.price.toString().trim().length > 0 ? variant.price.toString() : "",
      [`${storeMarketCode}_sku`]: variant && variant.sku ? variant.sku : "",
      [`${storeMarketCode}_compare_at_price`]: variant && variant.compare_at_price != undefined && variant.compare_at_price.toString().trim().length > 0 ? variant.compare_at_price.toString() : "",
      [`${storeMarketCode}_invalid`]: false,
      [`${storeMarketCode}_invalid_for_hundred`]: false,
      [`${storeMarketCode}_invalid_for_hundred_tooltip`]: "",
      [`${storeMarketCode}_currency_symbol`]: currencySymbol,
      [`${storeMarketCode}_currency_code`]: currencyCode,
      [`${storeMarketCode}_rule`]: rule
    })
  }
}

export const convertProductsIntoReqFormat = (unSavedProductIds, rowData, storeMarkets) => {
  // get the list of unique products
  // const uniqueProductIds = [...new Set(rowData.map((p) => p.productId))]
  // Iterate for each markets 
  let toBeUpdatedProducts = []
  for (const productId of unSavedProductIds) {
    let toBeUpdatedProduct = {
      id: productId,
      variants: []
    }
    const variants = rowData.filter((r) => r.productId === productId)
    for (const market of storeMarkets) {
      const keyToCheck = `${market.regionCode}_price`
      // Check the prices for all the variants of this product for this market region code
      // If all the variants for the given market have values then send those in the api request body
      const variantsHavingValue = variants.filter((v) => v[keyToCheck])

      if (variantsHavingValue.length === variants.length) {
        // Proceed with the market and variant
        // Iterate for each variants for the given product
        for (const variant of variants) {
          const toBeUpdatedVariant = {
            title: variant.originalVariantTitle,
            region_code: market.regionCode,
            sku: variant[`${market.regionCode}_sku`] ? variant[`${market.regionCode}_sku`] : "",
            compare_at_price: variant[`${market.regionCode}_compare_at_price`] ? parseFloat(variant[`${market.regionCode}_compare_at_price`]) : "",
            price: variant[`${market.regionCode}_price`] ? parseFloat(variant[`${market.regionCode}_price`]) : ""
          }
          toBeUpdatedProduct.variants = [...toBeUpdatedProduct.variants, toBeUpdatedVariant]
        }
      }
    }
    toBeUpdatedProducts = [...toBeUpdatedProducts, toBeUpdatedProduct]
  }
  return toBeUpdatedProducts
}
export const numberParser = (params, storeRoundings) => {
  const colId = params.column.colId.split("_")[0];
  let rule = null;
  if(params.data && params.data[colId + "_rule"]) {
    rule = params.data[colId + "_rule"];
  }
  if (params.newValue) {
    // If any non digit  exist 
    if (isNaN(params.newValue)) {
      const numberData = params.newValue.match(/[0-9.]+/);
      if (numberData) {
        const subString = `${numberData.toString().split(".")[0].substring(0, 14)}.${numberData.toString().split(".")[1]}`;
        if(rule) {
          return roundingFun(parseFloat(subString), rule).toString();
        } else {
          return parseFloat(subString).toFixed(2);
        }
      } else {
        if (params.oldValue) {
          return parseFloat(params.oldValue);
        } else {
          return ""
        }
      }
    }
    const subString = `${params.newValue.toString().split(".")[0].substring(0, 14)}.${params.newValue.toString().split(".")[1]}`
    if(rule) {
      return roundingFun(parseFloat(subString), rule).toString();
    } else {
      return parseFloat(subString).toFixed(2);
    }
  } else {
      return  ""
  }
}

const roundingFun = (value, roundingRule) => {
  const refRule = roundingRule.reference_rule;
  let multiCurrencyPrice = value;
  const bigDecimal = multiCurrencyPrice / parseInt(refRule);
  const intValue = parseInt(bigDecimal) * parseInt(refRule);
  let oldRoundingFactor = multiCurrencyPrice - intValue;
  oldRoundingFactor = parseFloat(oldRoundingFactor.toFixed(2));

  if (oldRoundingFactor === 0) {
      oldRoundingFactor = refRule;
  }

  if (oldRoundingFactor == roundingRule.rule) {
      //Do nothing
  } else if (oldRoundingFactor < roundingRule.rule) {
      multiCurrencyPrice = Math.floor(multiCurrencyPrice / refRule) * refRule + roundingRule.rule;
  } else {
      multiCurrencyPrice = Math.ceil(multiCurrencyPrice / refRule) * refRule + roundingRule.rule;
  }
  return  parseFloat(multiCurrencyPrice).toFixed(2);
}

export const numberValueFormatter = (params, columnName) => {
  // const colId = params.column.colId.split("_")[0];

  // let rule = null;
  // if(params.data && params.data[colId + "_rule"]) {
  //   rule = params.data[colId + "_rule"];
  // }

  // if(params.value) {
  //   return (
  //     rule ?
  //     (params.data[colId + "_currency_symbol"] || '') + " " + roundingFun(parseFloat(params.value), rule) :
  //     (params.data[colId + "_currency_symbol"] || '') + " " + parseFloat(params.value).toFixed(2)
  //   );
  // } else {
  //   return ""
  // }

  const colId = params.column.colId.split("_")[0];
  if(params.value) {
    return (
      (params.data[colId + "_currency_symbol"] || '') + " " + parseFloat(params.value).toFixed(2)
    );
  } else {
    return ""
  }
};

export const rowGroupHeaderName = (rowData, params) => {
  //Find the product name from the product id
  const foundProduct = rowData.find(
    p => String(p.productId) == params.value
  );
  if (foundProduct)
    return foundProduct.productTitle;

  return "";
};

export const checkEditableFlag = (params) => {
  const regionCode = params.colDef.field.split('_')[0]
  const colId = `${regionCode}_invalid_for_hundred`
  if(!params.data.inconsistentProduct && regionCode === params.data.primayMarket) {
    return true;
  }
  return (params.data.isEditable && !params.data[colId] && !params.data.inconsistentProduct)
}

export const handleCellClass = (params) => {
  const regionCode = params.colDef.field.split('_')[0]
  const colId = `${regionCode}_invalid_for_hundred`
  return (!params.data.isEditable || params.data[colId]) ? 'disabled' : ''
}

export const getPrimaryMarket = (storeMarkets, storeMarketCode) => {
  return storeMarkets.find((storeMarket) => storeMarket.regionCode === storeMarketCode).isPrimaryMarket
}