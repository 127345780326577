import * as types from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    markets: [],
    error: {}
}

export default (state = initialState, action) => {
    switch (action.type) {

        case types.GET_MARKETS_BEGIN:
        case types.CREATE_MARKET_BEGIN:
        case types.UPDATE_MARKET_BEGIN:
        case types.DELETE_MARKET_BEGIN:
            return { ...state, isLoading: true, error: {} };

        case types.GET_MARKETS_SUCCESS:
            return { ...state, markets: action.payload.markets, isLoading: false, error: {} };

        case types.CREATE_MARKET_SUCCESS:
          const markets = state.markets;
          const restOfWorld = markets.find(i => i.regionCode === "intl");
          if(restOfWorld) {
            const index = markets.indexOf(restOfWorld);
            if(index > -1) {
              markets.splice(index, 1);
              markets.push(action.payload.market);
              markets.splice(markets.length, 0, restOfWorld);
              return {...state, markets: markets, isLoading: false, error: {}};
            } else {
              return { ...state, markets: [...state.markets, { ...action.payload.market }], isLoading: false, error: {} };
            }
          } else {
            return { ...state, markets: [...state.markets, { ...action.payload.market }], isLoading: false, error: {} };
          }

        case types.UPDATE_MARKET_SUCCESS:
            const newMarkets = [...state.markets]
            const foundIndex = newMarkets.findIndex((ma) => ma.id === action.payload.market.id)
            if (foundIndex > -1) {
                newMarkets[foundIndex] = { ...action.payload.market }
            }
            return { ...state, markets: newMarkets, isLoading: false, error: {} };

        case types.DELETE_MARKET_SUCCESS:
          const oldMarkets = [...state.markets];
          const foundIndexForMarket = oldMarkets.findIndex((ma) => ma.id === action.payload.market.id);
          if (foundIndexForMarket > -1) {
            oldMarkets.splice(foundIndexForMarket, 1);
          }
          return { ...state, markets: oldMarkets, isLoading: false, error: {} };


        case types.GET_MARKETS_FAILURE:
        case types.CREATE_MARKET_FAILURE:
        case types.UPDATE_MARKET_FAILURE:
        case types.DELETE_MARKET_FAILURE:
            return { ...state, isLoading: false, error: action.payload.error };

        default:
            return state
    }
};
