import React from 'react';
import SupportCardWidget from '../../components/SupportCardWidget';
import SectionDivider from '../../components/SectionDivider';
import RelationshipManager from '../../components/RelationshipManager';
import DeleteApp from '../../components/DeleteApp';
import OtherMLVedaApps from '../../components/OtherMLVedaApps';
import EmailSettings from '../../components/EmailSettings';
import { useState } from 'react';
import MCPFooterHelp from '../../components/common/MCPFooterHelp';

const SupportContainer = props => {
  const [isShowFaq, setIsShowFaq] = useState(false);

  const toggleFaq = () => {
    setIsShowFaq(!isShowFaq);
  }
  return (
    <div className="row">
      <div className="cl-10 my-0 mx-auto">
        {!isShowFaq && <div>
          {/* Section foe Support Card widget */}
          {/* <div className="row">
            <div className="cl-4">
              <SupportCardWidget toggleFaq={toggleFaq} />
            </div>
            <div className="cl-4">
              <SupportCardWidget />
            </div>
            <div className="cl-4">
              <SupportCardWidget />
            </div>
          </div> */}
          {/* <SectionDivider /> */}
          {/* Section for relationship manager */}
          <RelationshipManager />
          <SectionDivider />
          <DeleteApp />
          <SectionDivider />
          <OtherMLVedaApps />
        </div>}
        {isShowFaq && <EmailSettings toggleFaq={toggleFaq}/>}
        <MCPFooterHelp />
      </div>
    </div>


  );
};


export default SupportContainer;