import React from 'react';
import PropTypes from 'prop-types';
import Styles from './styles.module.scss';
import { Autocomplete, Button, Icon } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

const ManageProducts = ({ updateSearchText, searchText, ...props }) => {
  return (
    <div className={`${Styles['container']} justify-content-between`}>
      <div className={`${Styles['left-container']}`} onKeyDown={(e) => props.keyPressEvent(e)}>
        <Autocomplete.TextField
          onChange={updateSearchText}
          value={searchText}
          prefix={<Icon source={SearchMinor} color="inkLighter" />}
          placeholder="Filter products"
        />
      </div>
      <div className={`${Styles['right-container']}`}>
        <Button
          disabled={props.searchApiCalled}
          primary
          onClick={() => props.onSearch(searchText)}
          loading={props.searchApiCalled}>Search</Button>
      </div>
    </div>
  );
};



export default ManageProducts;