import React from 'react';
import { FooterHelp } from '@shopify/polaris';
import ceoImg from '../../../assets/images/CEO.png';

const MCPFooterHelp = (props) => {
  return (
    <FooterHelp>
      <div className="main-div">
        <img src={ceoImg} alt="Img" />
        <div>Need help? Contact your relation manager at <span className="emailLink">support@mlveda.com</span></div>
      </div>
    </FooterHelp>
  )
}

export default MCPFooterHelp;