import APIService from "./APIService";

const API_END_POINT = process.env.REACT_APP_ADMIN_API_ENDPOINT + "store-markets";

export default {
    getMarkets() {
        return APIService.get(API_END_POINT);
    },
    getMarket(id) {

    },
    createMarket(postData) {
        return APIService.post(API_END_POINT, postData);
    },
    updateMarket(id, putData) {
        return APIService.put(`${API_END_POINT}/${id}`, putData);
    },
    deleteMarket(id) {
      return APIService.delete(`${API_END_POINT}/${id}`);
    },
    getSetCurrencies(regionName) {
      return APIService.get(API_END_POINT + '/set-currencies/' + regionName);
    },
    getDisplayCurrencies(regionName) {
      return APIService.get(API_END_POINT + '/display-currencies/' + regionName);
    }
}