import {
    GET_ONBOARDING_BEGIN, GET_ONBOARDING_SUCCESS, GET_ONBOARDING_FAILURE,
    UPDATE_ONBOARDING_BEGIN, UPDATE_ONBOARDING_SUCCESS, UPDATE_ONBOARDING_FAILURE
} from '../actions/actionTypes';
const initialState = {
    isLoading: true,
    onboarding: null,
    error: {}
}

export default (state = initialState, action) => {
    switch (action.type) {

        case GET_ONBOARDING_BEGIN:
        case UPDATE_ONBOARDING_BEGIN:
            return { ...state, isLoading: true, error: {} };

        case GET_ONBOARDING_SUCCESS:
        case UPDATE_ONBOARDING_SUCCESS:
            return { ...state, onboarding: action.payload.onboarding, isLoading: false, error: {} };

        case GET_ONBOARDING_FAILURE:
        case UPDATE_ONBOARDING_FAILURE:
            return { ...state, isLoading: false, error: action.payload.error };

        default:
            return state
    }
};
