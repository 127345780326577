import React from 'react';
import PropTypes from 'prop-types';
import Styles from './styles.module.scss';
import { DisplayText } from '@shopify/polaris';

const Title = ({ displayText }) => {
  return (
    <div className="text-align-center mt-15">
      <DisplayText
        size="large"
      >
        {displayText}
      </DisplayText>
    </div>
  );
};

Title.propTypes = {
  displayText: PropTypes.string.isRequired
};

export default Title;